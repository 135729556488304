<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('listName') }}:
        om-tooltip.pl-1
          span {{ $t('integrations.interspire.tooltip.contactListName') }}
      .col-sm-8
        select.form-control(
          :class="{ 'is-invalid': $v.settings.contactListName.$error }"
          v-model="settings.contactListName"
        )
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

    template(v-for="fieldName in ['firstNameField', 'lastNameField', 'sourceField']")
      .row.align-items-center.mb-2
        .col-sm-4.col-form-label
          label.mb-0 {{ $t(`integrations.interspire.fields.${fieldName}`, { brand: brandName }) }}
          om-tooltip.pl-1
            span {{ $t(`integrations.interspire.tooltip.${fieldName}`, { brand: brandName }) }}
        .col-sm-8
          select.form-control(v-model="settings[fieldName]")
            option(:value="null") {{ $t('select') }}
            template(v-for="field in fields")
              option(:value="field.id") {{ field.name }}
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import GET_INTEGRATION_FIELDS from '@/graphql/GetIntegrationFields.gql';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fields: [],
        settings: {
          contactListName: null,
          firstNameField: null,
          lastNameField: null,
          sourceField: null,
        },
      };
    },

    validations: {
      settings: {
        contactListName: {
          required,
        },
      },
    },

    apollo: {
      lists: {
        query: GET_INTEGRATION_LISTS,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result() {
          this.$emit('loaded');
        },
      },

      fields: {
        query: GET_INTEGRATION_FIELDS,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
            listId: this.settings.contactListName,
          };
        },
      },
    },
  };
</script>
