<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.miniCrm.fields.moduleName') }}
        om-tooltip.pl-1
          span {{ $t('integrations.miniCrm.tooltip.moduleName') }}
      .col-sm-8
        select.form-control(
          :class="{ 'is-invalid': $v.settings.moduleName.$error }"
          v-model="settings.moduleName"
        )
          option(:value="null") {{ $t('select') }}
          template(v-for="module in modules")
            option(:value="module.id") {{ module.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.miniCrm.fields.statusName') }}
        om-tooltip.pl-1
          span {{ $t('integrations.miniCrm.tooltip.statusName') }}
      .col-sm-8
        select.form-control(
          :disabled="!settings.moduleName || !statuses.length"
          v-model="settings.statusName"
        )
          option(:value="null") {{ $t('select') }}
          template(v-for="status in statuses")
            option(:value="status.id") {{ status.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.miniCrm.fields.userName') }}
        om-tooltip.pl-1
          span {{ $t('integrations.miniCrm.tooltip.userName') }}
      .col-sm-8
        select.form-control(
          :disabled="!settings.moduleName || !users.length"
          v-model="settings.userName"
        )
          option(:value="null") {{ $t('select') }}
          template(v-for="user in users")
            option(:value="user.id") {{ user.name }}

    //- .row.align-items-center.mb-2
    //-   div.col-sm-4.col-form-label
    //-     label(for='checkExistence') {{ $t('integrations.miniCrm.fields.checkExistence') }}
    //-     om-tooltip
    //-       span {{ $t('integrations.miniCrm.tooltip.checkExistence') }}
      .col-sm-8
        toggle-button(class="mb-0"
                      :width="50"
                      :height="24"
                      :value="parseInt(settings.checkExistence, 10) == true || settings.checkExistence"
                      @change="settings.checkExistence = $event.value"
                      :sync="true")
  integration-binding(
    v-if="settings.moduleName && fields && fields.length"
    :type="globalIntegration.type"
    :bindings.sync="bindings"
    :fields.sync="fields"
  )
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import GET_MINI_CRM_DATAS from '@/graphql/GetMiniCrmDatas.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: { IntegrationBinding },
    mixins: [integrationSetting],

    data() {
      return {
        modules: [],
        statuses: [],
        fields: [],
        users: [],
        settings: {
          // moduleId: '',
          moduleName: null,
          // subscriberStatus: '',
          statusName: null,
          userName: null,
        },
        integrationData: null,
      };
    },

    validations: {
      settings: {
        moduleName: {
          required,
        },
      },
    },

    apollo: {
      integrationData: {
        query: GET_MINI_CRM_DATAS,
        variables() {
          return {
            integrationId: this.globalIntegration._id,
            categoryId: this.settings.moduleName,
          };
        },
        result({
          data: {
            integrationData: { statuses, users, fields },
          },
        }) {
          this.statuses = statuses || [];
          this.users = users || [];
          this.fields = fields;
        },
      },
    },

    watch: {
      'settings.moduleName': function (n, old) {
        if (old !== null) {
          this.bindings.forEach((b) => {
            b.externalId = null;
          });
          this.settings.statusName = null;
          this.settings.userName = null;
        }
      },
    },

    created() {
      this.$apollo
        .query({
          query: GET_INTEGRATION_LISTS,
          variables: {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          },
        })
        .then(({ data: { lists } }) => {
          this.modules = lists;
          delete this.settings.checkExistence;
          this.$emit('loaded');
        });
    },
  };
</script>
