<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
      .col-sm-8
        select#listName.form-control(
          :class="{ 'is-invalid': $v.settings.listId.$error }"
          v-model="settings.listId"
        )
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.mailUp.fields.group') }}
        om-tooltip.pl-1
          span {{ $t('integrations.mailUp.tooltip.group') }}
      .col-sm-8
        select#group.form-control(:disabled="!settings.listId" v-model="settings.groupId")
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="group in groups")
            option(:value="group.id") {{ group.name }}
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.mailUp.fields.sourceId') }}
        om-tooltip.pl-1
          span {{ $t('integrations.mailUp.tooltip.sourceId', { brand: brandName }) }}
      .col-sm-8
        input.form-control(v-model="settings.sourceId")
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.mailUp.fields.sourceValue') }}
        om-tooltip.pl-1
          span {{ $t('integrations.mailUp.tooltip.sourceValue', { brand: brandName }) }}
      .col-sm-8
        input.form-control(v-model="settings.sourceValue")

  integration-binding(:type="globalIntegration.type" :bindings.sync="bindings")
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import GET_MAIL_UP_GROUPS from '@/graphql/GetMailUpGroups.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        groups: [],
        settings: {
          listId: null,
          groupId: null,
          sourceId: '',
          sourceValue: '',
        },
      };
    },

    validations: {
      settings: {
        listId: {
          required,
        },
      },
    },

    apollo: {
      groups: {
        query: GET_MAIL_UP_GROUPS,
        variables() {
          return {
            integrationId: this.globalIntegration._id,
            listId: this.settings.listId,
          };
        },
      },
    },

    created() {
      this.$apollo
        .query({
          query: GET_INTEGRATION_LISTS,
          variables: {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          },
        })
        .then(({ data: { lists } }) => {
          this.lists = lists;
          this.$emit('loaded');
        });
    },
  };
</script>
