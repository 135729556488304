<template lang="pug">
.form-group.row.align-items-center
  div.col-sm-5.col-form-label
    label(:for='fieldName') {{ $t(`integrationFields.${fieldName}`, {brand: brandName}) + ':' }}
    om-tooltip.pl-1
      span(v-html="$t(`integrations.${type}.tooltip.${fieldName}${isSubUser && $te(`integrations.${type}.tooltip.${fieldName}WL`) ? 'WL' : ''}`, {brand: brandName})")
  .col-sm-7(v-if="edit")
    input.form-control(:id="fieldName" type='text' v-model="integrationData[fieldName]")
  .col-sm-7(v-else) {{integrationData[fieldName]}}
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: {
      type: { type: String, required: true },
      fieldName: { type: String, required: true },
      integrationData: { type: Object },
      edit: { type: Boolean, default: true },
    },
    computed: mapGetters(['brandName', 'isSubUser']),
  };
</script>
