<template lang="pug">
div
  template(v-if="isNewIntegrationFlow")
    .d-flex.justify-content-start
      .campaign-integration-card-logo.new-logo(:style="{ background: backgroundColor }")
        integration-logo(:type="integration.global.type" justImage)
      .campaign-integration-card-details
        .campaign-integration-card-name {{ $t(`integrations.${integration.global.type}.name`) }}
        .campaign-integration-card-name-details(
          v-if="integration.global.type === 'shopifyCustomer'"
          :title="integration.global.data.shopifyDomain"
        ) {{ integration.global.data.shopifyDomain }}
        .campaign-integration-card-name-details(v-else :title="integration.global.data.name") {{ integration.global.data.name }}
      .campaign-integration-card-actions
        slot(name="action")
  template(v-else)
    .d-flex.justify-content-center.flex-column
      .campaign-integration-card-logo.mb-2(:style="{ background: backgroundColor }")
        integration-logo(:type="integration.global.type" justImage)
        slot(name="action")
      .font-size-1--125.text-center.text-color-mild-gray {{ $t(`integrations.${integration.global.type}.name`) }}
      .font-size-0--875.text-center.text-color-mild-gray.brand-text-truncate.text-ellipsis.mx-auto(
        :title="integration.global.data.name"
      ) ({{ integration.global.data.name }})
</template>

<script>
  import { mapGetters } from 'vuex';
  import IntegrationLogo from '@/components/IntegrationLogo.vue';
  import { getIntegrationColors } from '@om/integrations';
  import { NEW_INTEGRATION_FLOW } from '@/utils/features';

  export default {
    components: {
      IntegrationLogo,
    },

    props: {
      integration: {
        type: Object,
      },
      small: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapGetters(['hasAccountFeature']),
      backgroundColor() {
        return getIntegrationColors(this.integration.global.type);
      },
      isNewIntegrationFlow() {
        return this.hasAccountFeature(NEW_INTEGRATION_FLOW);
      },
    },
  };
</script>
