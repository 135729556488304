export default (settings, bindings, brandName) => {
  if (settings.sourceField) {
    bindings.push({
      isFix: true,
      fixValue: settings.sourceFieldValue || brandName,
      externalId: settings.sourceField,
    });

    delete settings.sourceField;
    delete settings.sourceFieldValue;
  }

  if (settings.sourcePageUrlField) {
    bindings.push({
      externalId: settings.sourcePageUrlField,
      fieldId: 'url',
    });

    delete settings.sourcePageUrlField;
  }

  if (settings.firstNameField && settings.firstNameField !== '0') {
    const fieldIndex = bindings.findIndex((binding) => {
      return binding.fieldId === 'firstname';
    });
    if (fieldIndex > -1) {
      bindings[fieldIndex].externalId = settings.firstNameField;
    }

    delete settings.firstNameField;
  }

  if (settings.lastNameField && settings.lastNameField !== '0') {
    const fieldIndex = bindings.findIndex((binding) => {
      return binding.fieldId === 'lastname';
    });
    if (fieldIndex > -1) {
      bindings[fieldIndex].externalId = settings.lastNameField;
    }

    delete settings.lastNameField;
  }
};
