<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
      .col-sm-8
        select#listName.form-control(
          :class="{ 'is-invalid': $v.settings.listId.$error }"
          v-model="settings.listId"
        )
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.verticalResponse.fields.sourceInputName') }}
        om-tooltip.pl-1
          span {{ $t('integrations.verticalResponse.tooltip.sourceInputName') }}
      .col-sm-8
        select.form-control(
          v-model="settings.sourceInputName"
          @input="settings.sourceInputId = $event.target.value"
        )
          option(:value="null") {{ $t('select') }}
          template(v-for="field in fields")
            option(:value="field") {{ field }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.verticalResponse.fields.sourceInputValue') }}
        om-tooltip.pl-1
          span {{ $t('integrations.verticalResponse.tooltip.sourceInputValue') }}
      .col-sm-8
        input.form-control(v-model="settings.sourceInputValue")
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fields: [],
        settings: {
          listId: null,
          sourceInputId: null,
          sourceInputName: null,
          sourceInputValue: '',
        },
      };
    },

    validations: {
      settings: {
        listId: {
          required,
        },
      },
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { lists, fields },
          },
        }) {
          this.lists = lists;
          this.fields = fields;
          this.$emit('loaded');
        },
      },
    },
  };
</script>
