export default (settings, bindings, brandName) => {
  if (settings.sourceInputValue) {
    bindings.push({
      isFix: true,
      fixValue: settings.sourceInputValue || brandName,
      externalId: settings.sourceInputName,
    });

    delete settings.sourceInputName;
    delete settings.sourceInputValue;
  }
};
