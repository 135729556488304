<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
      .col-sm-8
        select#listName.form-control(
          :class="{ 'is-invalid': $v.settings.listType.$error }"
          v-model="settings.listType"
        )
          option(:value="null") {{ $t('select') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

  div(v-if="settings.listType && fields.length === 0 && !integrationError")
    loading-logo
  integration-binding(
    v-else-if="fields.length"
    :type="globalIntegration.type"
    :bindings.sync="bindings"
    :fields.sync="fields"
  )
  .campaign-integration-warnings.mb-3(v-if="integrationError")
    div {{ $t(`integrationWarnings.salesforce_premium`) }}

  .campaign-integration-warnings.mb-3(v-if="integrationError")
    div {{ integrationError.message }}
    div {{ 'Error code: ' + integrationError.errorCode }}
</template>

<script>
  import GET_INTEGRATION_FIELDS from '@/graphql/GetIntegrationFields.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [
          { id: 'contact', name: 'Contact' },
          { id: 'lead', name: 'Lead' },
        ],
        fields: [],
        settings: {
          listType: null,
        },
        integrationError: null,
      };
    },

    validations: {
      settings: {
        listType: {
          required,
        },
      },
    },
    watch: {
      'settings.listType': async function () {
        this.fields = [];
        await this.fetchFields();
      },
    },

    methods: {
      async fetchFields() {
        const {
          data: { fields },
        } = await this.$apollo.query({
          query: GET_INTEGRATION_FIELDS,
          variables: {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
            listId: this.settings.listType,
          },
        });
        const apiDisabled = fields.find((field) => field.errorCode === 'API_DISABLED_FOR_ORG');

        if (fields.length && apiDisabled) {
          this.integrationError = apiDisabled;
        } else if (fields.length) {
          this.fields = fields;
        }
      },
    },
  };
</script>
