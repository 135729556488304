import { render, staticRenderFns } from "./IntegrationBinding.vue?vue&type=template&id=374de284&scoped=true&lang=pug&"
import script from "./IntegrationBinding.vue?vue&type=script&lang=js&"
export * from "./IntegrationBinding.vue?vue&type=script&lang=js&"
import style0 from "./IntegrationBinding.vue?vue&type=style&index=0&id=374de284&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "374de284",
  null
  
)

export default component.exports