<template lang="pug">
integration-binding(:type="globalIntegration.type" :bindings.sync="bindings")
</template>

<script>
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    created() {
      this.$emit('loaded');
    },
  };
</script>
