<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
      .col-sm-8
        select#listName.form-control(
          :class="{ 'is-invalid': $v.settings.listId.$error }"
          v-model="settings.listId"
        )
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}
  integration-binding(ref="binding" :type="globalIntegration.type" :bindings.sync="bindings")
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        settings: {
          listId: null,
          optin: false,
        },
      };
    },

    validations: {
      settings: {
        listId: {
          required,
        },
      },
    },

    created() {
      this.$apollo
        .query({
          query: GET_INTEGRATION_LISTS,
          variables: {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          },
        })
        .then(({ data: { lists } }) => {
          this.lists = lists;
          this.$emit('loaded');
        });
    },
    methods: {
      checkBinding() {
        return this.$refs.binding.checkBindingRequirements();
      },
    },
  };
</script>
