<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.moosend.fields.mailingList') }}
        om-tooltip.pl-1
          span {{ $t('integrations.moosend.tooltip.mailingList') }}
      .col-sm-8
        select.form-control(
          :class="{ 'is-invalid': $v.settings.mailingList.$error }"
          v-model="settings.mailingList"
        )
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

  div(v-if="lists.length && settings.mailingList && fields.length")
    integration-binding(
      :type="globalIntegration.type"
      :bindings.sync="bindings"
      :fields.sync="fields"
    )
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fieldsByListId: {},
        settings: {
          mailingList: null,
          firstNameField: null,
          lastNameField: null,
          sourceField: null,
          sourceText: '',
        },
      };
    },

    computed: {
      fields() {
        return this.settings.mailingList
          ? this.fieldsByListId[this.settings.mailingList].map((f) => {
              return { id: f, name: f };
            })
          : [];
      },
    },

    validations: {
      settings: {
        mailingList: {
          required,
        },
      },
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { lists, fields },
          },
        }) {
          this.lists = lists;
          this.fieldsByListId = fields;
          this.$emit('loaded');
        },
      },
    },
  };
</script>
