export default (settings, bindings) => {
  const doubleOptin = {
    none: 1,
    'double opt-in': 4,
  };
  if (settings.doubleOptin === true || settings.doubleOptin === false) {
    settings.doubleOptin =
      settings.doubleOptin === true ? doubleOptin['double opt-in'] : doubleOptin.none;
  }
  if (settings.customFieldInput && settings.customFieldValue) {
    bindings.push({
      isFix: true,
      fixValue: settings.customFieldValue,
      externalType: null,
      externalId: settings.customFieldInput,
    });
    delete settings.customFieldInput;
    delete settings.customFieldValue;
  }
  if (settings.sourceUrlFieldInput) {
    const fieldIndex = bindings.findIndex((binding) => {
      return binding.fieldId === 'url';
    });
    if (fieldIndex > -1) {
      bindings[fieldIndex].externalId = settings.sourceUrlFieldInput;
    }
    delete settings.sourceUrlFieldInput;
  }
};
