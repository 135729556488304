import GET_INTEGRATION_RESYNC_DATA from '@/graphql/GetIntegrationResyncData.gql';
import FAILED_SUBSCRIBERS_BETWEEN_DATES from '@/graphql/FailedSubscribersBetweenDates.gql';
import ADD_FAILED_RESYNC_ROW from '@/graphql/AddFailedResyncRow.gql';
import failedIntegrationResyncStorage from '@/services/integrations/resync/storage';

const RESYNC_STATUS_TYPES = {
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
};

class FailedIntegrationResyncService {
  constructor(databaseId, apollo) {
    this.databaseId = databaseId;
    this.$apollo = apollo;
  }

  isInProgress(logItem) {
    return logItem.status === RESYNC_STATUS_TYPES.IN_PROGRESS;
  }

  isInDone(logItem) {
    return logItem.status === RESYNC_STATUS_TYPES.DONE;
  }

  hasNamedError(logItem) {
    return !!logItem.errorCode;
  }

  isDeleted(rowItem) {
    return rowItem.integrationType === 'deleted';
  }

  hasError(rowItem) {
    return ['hardErrors', 'unknownErrors'].includes(rowItem.integrationStatus);
  }

  getIntegrationType() {
    return failedIntegrationResyncStorage.getIntegrationType();
  }

  async prepareForResync(rowItem) {
    const { campaignId, integrationId, integrationType, errorCode } = rowItem;

    let { errorCount } = rowItem;

    if (!errorCount) {
      errorCount = await this.fetchErrorCount(campaignId, integrationId, errorCode);
    }

    failedIntegrationResyncStorage.addToStore({
      databaseId: this.databaseId,
      campaignId,
      integrationId,
      errorCount,
      integrationType,
      errorCode,
    });
  }

  getErrorCount() {
    return failedIntegrationResyncStorage.getErrorCount();
  }

  getResyncDataFromSession() {
    return failedIntegrationResyncStorage.getExisting();
  }

  updateSessionWithDate({ date }) {
    const existing = failedIntegrationResyncStorage.getExisting();

    if (!existing) {
      throw Error(
        '[integration-resync]: not existing data in session storage unable to update with period',
      );
    }

    Object.assign(existing, date);
    failedIntegrationResyncStorage.update(existing);
  }

  updateSessionWithErrorCount(errorCount) {
    const existing = failedIntegrationResyncStorage.getExisting();

    if (!existing) {
      throw Error(
        '[integration-resync]: not existing data in session storage unable to update error count',
      );
    }

    existing.errorCount = errorCount;

    failedIntegrationResyncStorage.update(existing);
  }

  getIntegrationProperties(integrationSettings) {
    // handle new integration flow
    if (integrationSettings.hasOwnProperty('bindings')) {
      return {
        newIntegrationId: integrationSettings.id,
        newIntegrationType: integrationSettings.type,
        newIntegrationName: integrationSettings.name,
      };
    }

    return {
      newIntegrationId: integrationSettings._id,
      newIntegrationType: integrationSettings.type,
      newIntegrationName: integrationSettings.data.name,
    };
  }

  async integrationSetupDone(newIntegration) {
    const {
      campaignId,
      integrationId: oldIntegrationId,
      interval,
      errorCount,
      errorCode,
    } = failedIntegrationResyncStorage.getExisting();

    failedIntegrationResyncStorage.deleteResync();

    const { newIntegrationId, newIntegrationType, newIntegrationName } =
      this.getIntegrationProperties(newIntegration);

    const {
      data: { addFailedResyncRow: resyncLogId },
    } = await this.$apollo.mutate({
      mutation: ADD_FAILED_RESYNC_ROW,
      variables: {
        newIntegrationId,
        newIntegrationType,
        newIntegrationName,
        oldIntegrationId,
        campaignId,
        interval,
        errorCount,
        errorCode,
      },
    });

    failedIntegrationResyncStorage.updateResyncPollTimestamp();
    failedIntegrationResyncStorage.updateResyncPollLogId(resyncLogId);

    return resyncLogId;
  }

  async getIntegrationResyncData({ domainIds = [], campaignIds = [], dates = null, resyncLogId }) {
    const {
      data: { integrationResyncData },
    } = await this.$apollo.query({
      query: GET_INTEGRATION_RESYNC_DATA,
      variables: {
        domainIds,
        campaignIds,
        dates,
        resyncLogId,
      },
    });

    return integrationResyncData;
  }

  async fetchErrorCount(campaignId, integrationId, errorCode, interval) {
    const {
      data: {
        failedSubscribersBetweenDates: { leadsCount },
      },
    } = await this.$apollo.query({
      query: FAILED_SUBSCRIBERS_BETWEEN_DATES,
      variables: {
        campaignId,
        integrationId,
        errorCode,
        interval,
      },
    });

    return leadsCount;
  }
}

export default FailedIntegrationResyncService;
