export default (settings, bindings, brandName) => {
  if (settings.sourceInputId) {
    bindings.push({
      isFix: true,
      fixValue: settings.sourceFieldValue || brandName,
      externalId: settings.sourceInputId,
    });

    delete settings.sourceInputId;
    delete settings.sourceFieldValue;
  }
};
