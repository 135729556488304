<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('listName') }}:
      .col-sm-8
        select.form-control(
          :class="{ 'is-invalid': $v.settings.databaseId.$error }"
          v-model="settings.databaseId"
        )
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

    div(v-if="fields.length")
      integration-binding(
        :type="globalIntegration.type"
        :bindings.sync="bindings"
        :fields.sync="fields"
      )
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import GET_INTEGRATION_FIELDS from '@/graphql/GetIntegrationFields.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fields: [],
        settings: {
          databaseId: null,
          firstnameField: 0,
          lastnameField: 0,
          emailField: 0,
          sourceFieldName: 0,
          sourceFieldValue: '',
        },
      };
    },

    validations: {
      settings: {
        databaseId: {
          required,
        },
      },
    },

    watch: {
      'settings.databaseId': async function () {
        const {
          data: { fields },
        } = await this.$apollo.query({
          query: GET_INTEGRATION_FIELDS,
          variables: {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
            listId: this.settings.databaseId,
          },
        });
        this.fields = fields;
      },
    },

    async created() {
      try {
        const {
          data: { lists },
        } = await this.$apollo.query({
          query: GET_INTEGRATION_LISTS,
          variables: {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          },
        });

        this.lists = lists;
        this.$emit('loaded');
      } catch (e) {
        console.error(e.message);
        this.$emit('loaded');
      }

      try {
        const {
          data: { fields },
        } = await this.$apollo.query({
          query: GET_INTEGRATION_FIELDS,
          variables: {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
            listId: this.settings.databaseId,
          },
        });

        this.fields = fields;
      } catch (e) {
        console.error(e.message);
      }
    },
  };
</script>
