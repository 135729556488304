<template lang="pug">
multiselect(
  :options="selectOptions"
  v-model="taggableValue"
  :clearOnSelect="false"
  :multiple="true"
  :taggable="true"
  track-by="key"
  label="value"
  :selectedLabel="$t('multiselect.labels.selected')"
  :deselectLabel="$t('multiselect.labels.deselect')"
  :tagPlaceholder="$t('multiselect.labels.tag')"
  :placeholder="getPlaceholder"
  @tag="addTag"
)
  span(slot="noOptions") {{ $t('multiselect.slot.noOptions') }}
</template>

<script>
  import { getConfigForIntegration } from '@om/integrations';

  export default {
    name: 'TagMultiselect',
    props: ['type', 'binding', 'options'],
    computed: {
      getPlaceholder() {
        return this.$te(`integrations.${this.type}.multiselect.placeholder`)
          ? this.$t(`integrations.${this.type}.multiselect.placeholder`)
          : this.$t('multiselect.labels.placeholder');
      },
      getTagSeparator() {
        return getConfigForIntegration(this.type)?.tagSeparator || [','];
      },
      selectOptions() {
        const options = this.options?.length ? this.options : [];
        return [...this.taggableValue, ...options.map((key) => ({ key, value: key }))];
      },
      taggableValue: {
        get() {
          const tags = this.binding.fixValue
            ? this.binding.fixValue.split(this.getTagSeparator)
            : [];
          return tags.map((key) => ({ key, value: key }));
        },

        set(currentValue) {
          const binding = this.binding;
          const tagSeparator = this.getTagSeparator;

          if (Array.isArray(currentValue)) {
            binding.fixValue = currentValue.map(({ value }) => value).join(tagSeparator);
          } else {
            const items = this.binding.fixValue ? this.binding.fixValue.split(tagSeparator) : [];

            const tags = new Set(items);

            if (currentValue.includes(tagSeparator)) {
              const multiple = currentValue.split(',');
              multiple.forEach((raw) => {
                const tag = raw.trim();
                if (tag.length) {
                  tags.add(tag);
                }
              });
            } else {
              tags.add(currentValue);
            }

            binding.fixValue = Array.from(tags.values()).join(tagSeparator);
          }

          this.$emit('update:binding', binding);
        },
      },
    },
    methods: {
      addTag(newTag) {
        this.taggableValue = newTag;
      },
    },
  };
</script>

<style scoped></style>
