<template lang="pug">
mixin om-input-fields
  template(v-if="isTag")
    span {{ $t(`integrations.${type}.tagFields.${binding.externalId}`) }}
  template(v-else)
    input.form-control.mb-0(
      v-if="binding.isFix"
      :value="binding.fixValue || ''"
      @input="binding.fixValue = $event.target.value.trim()"
    )
    span(v-else) {{ binding.name }}

mixin integration-fields
  template(v-if="combinedSelect")
    .integration-new-flow
      combined-multiselect(
        :binding.sync="binding"
        :fields.sync="fields"
        :hasError="hasError"
        :isEmailBinding="isEmailBinding"
      )
  template(v-else-if="isTag")
    tag-multiselect(:binding="binding" :type="type")
  template(v-else-if="isMailChimp && isCheckbox")
    select.form-control.mb-3(v-model="binding.externalId")
      option(:value="null") {{ $t('select') }}
      option(v-for="gn in groupNames" :value="gn.id") {{ gn.title }}
  template(v-else-if="fields.length")
    select.form-control.mb-3(
      :disabled="(!isShopify && !isCopernica && binding.fieldId === 'email') || ((isSmsBump || isCampaignMonitor) && isNameBinding)"
      @change="changeBinding(binding, $event)"
    )
      option(:value="null") {{ $t('select') }}
      template(v-if="(isSmsBump || isCampaignMonitor) && isNameBinding")
        option(value="name" :selected="getDefaultExternalBindingField(binding) === 'name'") name
      template(v-if="isPostscriptLegacy && isEmailBinding")
        option(value="email" :selected="getDefaultExternalBindingField(binding) === 'email'") email
      template(v-if="isSendGrid && isEmailBinding")
        option(value="email" :selected="getDefaultExternalBindingField(binding) === 'email'") email
      template(v-for="field in fields")
        option(
          :value="field.id"
          v-if="!isEmailOption(field) || (binding.type === 'email' && isEmailOption(field))"
          :selected="getDefaultExternalBindingField(binding) === field.id"
        ) {{ formatFieldText(field) }}
  template(v-else)
    input.form-control(:value="getDefaultExternalBindingField(binding)" @input="onInput")

div(:class="{ 'd-flex': isNewFlow }")
  template(v-if="isNewFlow")
    .om-campaign-fields(:class="{ 'with-delete-static-field': binding.isFix && !isTag }")
      +om-input-fields
    .d-flex.arrow-block
      UilArrowRight(size="1.5rem")
    .integration-fields(:class="{ 'with-delete-static-field': binding.isFix && !isTag }")
      +integration-fields
    .delete-static-field(v-if="binding.isFix && !isTag")
      om-button(
        icon="trashAlt"
        iconSize="1.715em"
        iconOnly
        ghost
        style="color: #505763"
        @click="removeStaticRow"
      )
  template(v-else)
    .row(:class="{ 'mb-3': !binding.isFix || isTag, 'is-tag': isTag }")
      .col-5
        +om-input-fields
      .col-6
        +integration-fields
      .col-1
        .fas.fa-trash-alt.cursor-pointer.mt-2(
          v-if="binding.isFix && !isTag"
          @click.stop="removeStaticRow"
        )
</template>

<script>
  /* eslint-disable vue/no-mutating-props */
  import { UilArrowRight } from '@iconscout/vue-unicons';
  import { getIntegrationsFixedFields } from '@om/integrations';
  import TagMultiselect from '@/components/IntegrationBindings/TagMultiselect';
  import CombinedMultiselect from '@/components/IntegrationBindings/CombinedMultiselect';

  export default {
    name: 'BindingRow',
    components: {
      UilArrowRight,
      CombinedMultiselect,
      TagMultiselect,
    },
    props: [
      'binding',
      'index',
      'fields',
      'type',
      'groupNames',
      'isStatic',
      'isNewFlow',
      'combinedSelect',
      'editMode',
      'hasError',
    ],
    data: () => ({ isNew: true }),
    computed: {
      isMailChimp() {
        return this.type === 'mailChimp';
      },
      isSmsBump() {
        return this.type === 'smsBump';
      },
      isCampaignMonitor() {
        return this.type === 'campaignMonitor';
      },
      isPostscriptLegacy() {
        return this.type === 'postscriptLegacy';
      },
      isSendGrid() {
        return this.type === 'sendGrid';
      },
      isCopernica() {
        return this.type === 'copernica';
      },
      isShopify() {
        return this.type === 'shopifyCustomer';
      },
      isCheckbox() {
        return this.binding.type === 'checkbox';
      },
      isNameBinding() {
        return ['firstname', 'lastname'].includes(this.binding.fieldId);
      },
      isEmailBinding() {
        return this.binding.fieldId === 'email';
      },
      isPhoneBinding() {
        return this.binding.type === 'phoneNumber';
      },
      isTag() {
        return this.binding.externalType === '__TAG__';
      },
    },

    methods: {
      formatFieldText(field) {
        return `${field.name}${field.required ? '*' : ''} ${field.type ? `(${field.type})` : ''}`;
      },
      getDefaultExternalBindingField(internalField) {
        if ((this.isSmsBump || this.isCampaignMonitor) && this.isNameBinding) {
          return 'name';
        }

        if (this.isPostscriptLegacy && this.isEmailBinding) {
          return 'email';
        }

        if (this.isSendGrid && this.isEmailBinding) {
          this.binding.fieldId = 'email';
          this.binding.externalId = 'email';
          return 'email';
        }

        if (!this.binding.externalId) {
          let id = null;
          if (
            this.isNew &&
            getIntegrationsFixedFields(this.type) &&
            getIntegrationsFixedFields(this.type)[internalField.fieldId]
          ) {
            id = getIntegrationsFixedFields(this.type)[internalField.fieldId];
          }

          if (!(this.fields || []).length || this.fields.some((f) => f.id === id)) {
            this.binding.externalId = id;
          }
        }

        if (this.type === 'getResponse') {
          const field = this.fields.find((field) => field.name === this.binding.externalId);
          if (field) {
            this.binding.externalId = field.id;
          }
        }

        if (
          (this.fields || []).length &&
          !this.fields.find((field) => field.id === this.binding.externalId)
        ) {
          this.binding.externalId = null;
        }

        return this.binding.externalId;
      },
      isEmailOption(option) {
        return option.id.toString().toLowerCase().indexOf('mail') !== -1;
      },
      removeStaticRow() {
        this.$emit('removeStaticRow', { binding: this.binding });
      },
      onInput($event) {
        this.binding.externalId = $event.target.value.trim();
        this.isNew = false;
      },
      changeBinding(binding, event) {
        const field = this.fields.find((f) => f.id === event.target.value);
        if (field) {
          binding.externalId = field.id;
          if (field.externalType) {
            binding.externalType = field.externalType;
          }
        } else {
          binding.externalId = '';
        }
        this.$emit('update:binding', binding);
      },
    },
  };
</script>

<style lang="sass">
  .brand-modal-body .row.is-tag
    .multiselect
      min-height: auto !important
      max-height: none !important
    .multiselect__tags-wrap
      max-height: none !important
</style>
