<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
        om-tooltip.pl-1
          span {{ $t('integrations.activeCampaign.tooltip.listName') }}
      .col-sm-8
        select#listName.form-control(v-model="settings.listId")
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.activeCampaign.fields.formName') }}
        om-tooltip.pl-1
          span {{ $t('integrations.activeCampaign.tooltip.formName') }}
      .col-sm-8
        select#formName.form-control(v-model="settings.formId")
          option(:value="null") {{ $t('select') }}
          template(v-for="form in forms")
            option(:value="form.id") {{ form.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.activeCampaign.fields.tags') }}
        om-tooltip.pl-1
          span {{ $t('integrations.activeCampaign.tooltip.tags') }}
      .col-sm-8
        multiselect(
          v-model="settings.tags"
          :options="tags"
          :multiple="true"
          :taggable="false"
          :show-labels="false"
          :placeholder="$t('itemSelected', { num: settings.tags.length })"
          :closeOnSelect="false"
        )
          template(slot="clear" slot-scope="props")
            .multiselect__clear {{ $t('itemSelected', { num: settings.tags.length }) }}
          template(slot="tag" slot-scope="props")
            span

    integration-binding(
      :type="globalIntegration.type"
      :bindings.sync="bindings"
      :fields.sync="fields"
    )
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { getIntegrationsFixedFields } from '@om/integrations';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    /**
     * @use fields - If you want to display drop-down fields in the binding you need to set in the following format {id: external id, name: display name in the dropdown}
     *
     * @returns {{sourceFields: [], settings: {listId: null, formId: null, sourceInputValue: null, sourceInputId: null, tags: []}, lists: [], fields: [], forms: [], tags: []}}
     */
    data() {
      return {
        lists: [],
        forms: [],
        sourceFields: [],
        tags: [],
        fields: [], // [{id: external id, name: display name in the drop-down}]
        settings: {
          listId: null,
          formId: null,
          sourceInputId: null,
          sourceInputValue: null,
          tags: [],
        },
      };
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { lists, forms, sourceFields, tags },
          },
        }) {
          this.lists = lists.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          this.forms = forms.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          this.tags = tags.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          });
          const fixedFields = Object.keys(getIntegrationsFixedFields('activeCampaign')).map((a) => {
            return {
              id: getIntegrationsFixedFields('activeCampaign')[a],
              name: this.$t(`integrations.activeCampaign.fields.${a}`, { key: a }),
            };
          });
          const sortedSourceField = sourceFields.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });

          this.fields = [...fixedFields, ...sortedSourceField];
          this.$emit('loaded');
        },
      },
    },
  };
</script>
