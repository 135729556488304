<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.ontraport.fields.tagName') }}
        om-tooltip.pl-1
          span {{ $t('integrations.ontraport.tooltip.tagName', { brand: brandName }) }}
      .col-sm-8
        select.form-control(v-model="settings.tagName")
          option(:value="null") {{ $t('select') }}
          template(v-for="tag in tags")
            option(:value="tag") {{ tag }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.ontraport.fields.sequence') }}
        om-tooltip.pl-1
          span {{ $t('integrations.ontraport.tooltip.sequence') }}
      .col-sm-8
        select.form-control(v-model="settings.sequence")
          option(:value="null") {{ $t('select') }}
          template(v-for="sequence in sequences")
            option(:value="sequence.id") {{ sequence.name }}
    integration-binding(
      :type="globalIntegration.type"
      :bindings.sync="bindings"
      :fields.sync="fields"
    )
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        tags: [],
        sequences: [],
        fields: [],
        settings: {
          tagName: null,
          sequence: null,
        },
      };
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { tags, sequences, fields },
          },
        }) {
          this.tags = tags;
          this.sequences = sequences;
          this.fields = fields;
          this.$emit('loaded');
        },
      },
    },
  };
</script>
