<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.automizy.fields.listId') }}
      .col-sm-8
        select(
          :class="{ 'form-control': true, 'is-invalid': $v.settings.listId.$error }"
          v-model="settings['listId']"
        )
          option(:value="null") {{ $t('select') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

    template(v-for="fieldName in ['firstNameField', 'lastNameField', 'urlField', 'customField']")
      .row.align-items-center.mb-2
        .col-sm-4.col-form-label
          label.mb-0 {{ $t(`integrations.automizy.fields.${fieldName}`, { brand: brandName }) }}:
          om-tooltip.pl-1
            span {{ $t(`integrations.automizy.tooltip.${fieldName}`, { brand: brandName }) }}
        .col-sm-8
          select.form-control(v-model="settings[fieldName]")
            option(:value="null") {{ $t('select') }}
            template(v-for="field in fields")
              option(:value="field.id") {{ field.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.automizy.fields.customFieldText', { brand: brandName }) }}
        om-tooltip.pl-1
          span {{ $t('integrations.automizy.tooltip.customFieldText', { brand: brandName }) }}
      .col-sm-8
        input.form-control(v-model="settings.customFieldText")
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        fields: [],
        lists: [],
        settings: {
          listId: null,
          firstNameField: null,
          lastNameField: null,
          urlField: null,
          customField: null,
          customFieldText: '',
        },
      };
    },

    validations: {
      settings: {
        listId: {
          required,
        },
      },
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { lists, fields },
          },
        }) {
          this.lists = lists;
          this.fields = fields;
          this.$emit('loaded');
        },
      },
    },
  };
</script>
