<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
      .col-sm-8
        select#listName.form-control(
          :class="{ 'is-invalid': $v.settings.listId.$error }"
          v-model="settings.listId"
        )
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.mailigen.fields.doubleOptin') }}
      .col-sm-8
        toggle-button.mb-0(:width="50" :height="24" v-model="settings.doubleOptin" :sync="true")

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.mailigen.fields.updateExisting') }}
        om-tooltip.pl-1
          span {{ $t('integrations.mailigen.tooltip.updateExisting') }}
      .col-sm-8
        toggle-button.mb-0(:width="50" :height="24" v-model="settings.updateExisting" :sync="true")

    template(v-if="settings.listId")
      integration-binding(
        :type="globalIntegration.type"
        :bindings.sync="bindings"
        :fields.sync="fields"
      )
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import GET_INTEGRATION_FIELDS from '@/graphql/GetIntegrationFields.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fields: [],
        settings: {
          listId: null,
          doubleOptin: false,
          updateExisting: false,
        },
      };
    },

    validations: {
      settings: {
        listId: {
          required,
        },
      },
    },

    apollo: {
      lists: {
        query: GET_INTEGRATION_LISTS,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result() {
          this.$emit('loaded');
        },
      },

      fields: {
        query: GET_INTEGRATION_FIELDS,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
            listId: this.settings.listId,
          };
        },
      },
    },
  };
</script>
