<template lang="pug">
.card.klaviyo-detection
  .row.align-items-center
    .col-auto
      img(:src="require('@om/integrations/src/assets/icons/klaviyo.svg')")
    .col
      om-body-text.pb-2(bt400xs) {{ $t('klaviyoDetection.title') }}
      .klaviyo-detection-desc(v-html="$t('klaviyoDetection.description')")
    .col-12.col-md-auto.justify-content-center.d-flex
      om-button(primary small @click="addKlaviyo") {{ $t('klaviyoDetection.btn') }}
</template>
<script>
  export default {
    methods: {
      addKlaviyo() {
        this.$emit('addKlaviyo');
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'
  .klaviyo-detection
    padding: 20px
    border: 1px solid #D8DDE1!important
    margin-bottom: 1rem
    &-desc
      color: $om-gray-600
      font-size: 0.75rem
      line-height: 1rem
      padding-right: 5px
</style>
