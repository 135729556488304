<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2(
      v-for="fieldName in ['emailFieldName', 'firstnameFieldName', 'lastnameFieldName', 'affiliateId', 'uniqueFieldName', 'uniqueFieldValue']"
    )
      .col-sm-4.col-form-label
        label.mb-0 {{ $t(`integrations.mailEngine.fields.${fieldName}`) }}
        //- om-tooltip
        //-   span {{ $t(`integrations.mailEngine.tooltip.${fieldName}`) }}
      .col-sm-8
        input.form-control(v-model="settings[fieldName]")
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label(for="hidden") {{ $t(`integrations.mailEngine.fields.hidden`) }}
      .col-sm-8
        toggle-button.mb-0(:width="50" :height="24" v-model="settings.hidden" :sync="true")
</template>

<script>
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        settings: {
          emailFieldName: '',
          firstnameFieldName: '',
          lastnameFieldName: '',
          affiliateId: '',
          uniqueFieldName: '',
          uniqueFieldValue: '',
          hidden: false,
        },
      };
    },

    created() {
      this.$emit('loaded');
    },
  };
</script>
