<template lang="pug">
.campaign-settings-notifyme
  .brand-disable-overlay(v-if="!inputFields && !status")
  .campaign-settings-box.campaign-settings-box-gray(
    :class="!inputFields && !status ? 'brand-disable-content' : ''"
  )
    .d-flex(:class="{ 'pb-2': status }") {{ $t('notifyMe.label') }}
      om-switch#notify-me.ml-3(:value="status" @switch="toggleNotifyMe")
    .row.flex-nowrap.mt-2.mb-3(v-if="status")
      .col-auto.brand-filterbar-col
        .brand-filterbar-tags(style="margin: 0 -6px")
          div(v-for="notifyRecord in emails")
            om-tooltip(v-if="notifyRecord.status === 'pending'") {{ $t(`notifyMe.status.${notifyRecord.status}`) }}
              template(slot="trigger")
                om-chip(
                  :key="notifyRecord.email"
                  slot="reference"
                  color="primary"
                  icon="hourglass"
                  removable
                  @remove="removeEmail(notifyRecord)"
                ) {{ notifyRecord.email }}
            div(v-else)
              om-chip(
                :key="notifyRecord.email"
                color="gray"
                removable
                @remove="removeEmail(notifyRecord)"
              ) {{ notifyRecord.email }}
      .col.row.flex-nowrap(:class="!emails.length ? 'pl-0' : ''")
        .col-auto.d-flex.align-items-center(v-if="emails.length")
          .brand-sort-separator-vertical
        .col-auto.flex-grow-1.d-flex.align-items-center(:class="!emails.length ? 'pl-0' : ''")
          .brand-merged-form-wrap.w-100
            .brand-merged-form.w-100(style="max-width: 21.25rem")
              input.form-control.form-control-md(
                v-model.trim="newEmail"
                :placeholder="$t('notifyMe.inputPlaceholder')"
                :class="{ 'is-invalid': $v.newEmail.$error }"
              )
              .btn.btn-primary.btn-sm(@click="addEmail") {{ $t('add') }}
            .brand-input-error-wrap
              .brand-input-error-bottom(v-if="$v.newEmail.$error && !$v.newEmail.required")
                i.fas.fa-exclamation-circle.mr-2.text-danger
                span {{ $t('requiredField') }}
              .brand-input-error-bottom(v-if="$v.newEmail.$error && !$v.newEmail.isCoolEmail")
                i.fas.fa-exclamation-circle.mr-2.text-danger
                span {{ $t('validEmail') }}
</template>

<script>
  import { email, required } from 'vuelidate/lib/validators';
  import TOGGLE_NOTIFY_ME from '@/graphql/ToggleNotifyMe.gql';
  import ADD_NOTIFY_ME_EMAIL from '@/graphql/AddNotifyMeEmail.gql';
  import REMOVE_NOTIFY_ME_EMAIL from '@/graphql/RemoveNotifyMeEmail.gql';

  export default {
    name: 'NotifyMe',
    props: {
      status: { type: Boolean, default: false },
      emails: { type: Array, default: () => [] },
      inputFields: { type: Boolean },
    },
    data() {
      return {
        newEmail: '',
      };
    },
    validations: {
      newEmail: {
        required,
        isCoolEmail(v) {
          return email(v.toLowerCase());
        },
      },
    },
    methods: {
      toggleNotifyMe(statusValue) {
        this.$apollo
          .mutate({
            mutation: TOGGLE_NOTIFY_ME,
            variables: {
              campaignId: parseInt(this.$route.params.id, 10),
              status: statusValue,
            },
          })
          .then(() => {
            this.$emit('update:status', statusValue);
          });
      },
      addEmail() {
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        }

        const hasSameEmail = this.emails.filter(
          (e) => e.email.toLowerCase() === this.newEmail.toLowerCase(),
        );
        if (!hasSameEmail.length) {
          this.$apollo
            .mutate({
              mutation: ADD_NOTIFY_ME_EMAIL,
              variables: {
                input: {
                  email: this.newEmail.toLowerCase(),
                  campaign: parseInt(this.$route.params.id, 10),
                },
              },
            })
            .then(({ data: { addNotifyEmail } }) => {
              this.$emit('update:emails', [...this.emails, { ...addNotifyEmail.entity }]);
              this.newEmail = '';
              this.$v.$reset();
            });
        }
      },
      validate() {
        this.$v.$touch();

        return !this.$v.$invalid;
      },
      removeEmail(notifyRecord) {
        this.$apollo
          .mutate({
            mutation: REMOVE_NOTIFY_ME_EMAIL,
            variables: {
              input: {
                email: notifyRecord.email,
                campaign: parseInt(this.$route.params.id, 10),
              },
            },
          })
          .then(() => {
            this.$emit(
              'update:emails',
              this.emails.filter((e) => e.email !== notifyRecord.email),
            );
          });
      },
    },
  };
</script>

<style lang="sass" scoped>
  .brand-filterbar-tags
    padding: 0

    .popper .popper__arrow
      border-color: #fff transparent transparent transparent !important
  .brand-filterbar-col
    max-width: 78% !important
  @media screen and (max-width: 1199px)
    .brand-filterbar-col
      max-width: 70% !important
  @media screen and (max-width: 575px)
    .brand-filterbar-col
      max-width: 60% !important
</style>
