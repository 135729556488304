<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="campaignCode") {{ $t('integrations.acerCCDB.campaignCode') }}:
      .col-sm-8
        input#campaignCode.form-control(
          :class="{ 'is-invalid': $v.settings.campaignCode.$error }"
          v-model="settings.campaignCode"
        )
  integration-binding(
    :type="globalIntegration.type"
    :bindings.sync="bindings"
    :fields.sync="fields"
  )
</template>

<script>
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        campaignCode: '',
        fields: [
          {
            id: 'email',
            name: 'Email',
          },
          {
            id: 'surname',
            name: 'Surname',
          },
          {
            id: 'name',
            name: 'Name',
          },
          {
            id: 'phoneNumber',
            name: 'Phone number',
          },
          {
            id: 'address',
            name: 'Address',
          },
          {
            id: 'address2',
            name: 'Address 2',
          },
          {
            id: 'city',
            name: 'City',
          },
          {
            id: 'postcode',
            name: 'Postcode',
          },
          {
            id: 'state',
            name: 'State',
          },
          {
            id: 'socialId',
            name: 'Social ID',
          },
          {
            id: 'contactAgreement',
            name: 'Contact agreement',
          },
          {
            id: 'contactAgreementThirdParty',
            name: 'Contact agreement third party',
          },
          {
            id: 'source',
            name: 'Source',
          },
          {
            id: 'prospectType',
            name: 'Prospect type',
          },
          {
            id: 'companyName',
            name: 'Company name',
          },
          {
            id: 'companySize',
            name: 'Company size',
          },
        ],
        settings: {
          campaignCode: null,
        },
      };
    },

    validations: {
      settings: {
        campaignCode: {
          required,
        },
      },
    },
    mounted() {
      this.$emit('loaded');
    },
  };
</script>
