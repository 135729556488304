<template lang="pug">
integration-binding(:type="globalIntegration.type" :bindings.sync="bindings" :fields="fields")
</template>

<script>
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    computed: {
      fields() {
        return [
          {
            name: 'phone',
            id: 'phone',
            required: true,
          },
          {
            name: 'email',
            id: 'email',
          },
        ];
      },
    },
    created() {
      this.$emit('loaded');
    },
  };
</script>
