<template lang="pug">
div
  .alert.alert-danger(v-if="!isValid") {{ $t('integrations.attentive.noPhoneBinding') }}
  integration-binding(
    v-if="bindings.some((item) => item.type === 'phoneNumber')"
    :type="globalIntegration.type"
    :bindings.sync="getPhoneNumberBindings"
    :fields="fields"
  )
</template>

<script>
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],
    data() {
      return {
        isValid: true,
      };
    },

    computed: {
      fields() {
        return [
          {
            name: 'phone',
            id: 'phone',
            required: true,
          },
        ];
      },
      getPhoneNumberBindings() {
        return this.bindings.filter((item) => item.type === 'phoneNumber');
      },
    },
    created() {
      this.$emit('loaded');
    },
    methods: {
      customValidation() {
        this.isValid = this.bindings.some((item) => item.type === 'phoneNumber' && item.externalId);

        return this.isValid;
      },
    },
  };
</script>
