<template lang="pug">
.col-auto.campaign-integration-card.campaign-integration-card-analytics.cursor-pointer(
  :class="{ 'new-flow': isNewIntegrationFlow }"
  @click="$router.push({ name: 'domains', query: { domain: domain.domain } })"
)
  template(v-if="isNewIntegrationFlow")
    .d-flex.justify-content-start
      .campaign-integration-card-logo.new-logo
        img(:src="require('@/assets/admin/svg/analytics3.svg')")
      .campaign-integration-card-details
        .campaign-integration-card-name
          .d-flex
            span Google Analytics
            .ml-3.d-flex.align-items-center
              .font-size-0--875.text-center.text-color-success-green.font-weight-semibold(
                v-if="isAnalyticsEnabled"
              ) {{ $t('enabled') }}
              .font-size-0--875.text-center.text-color-error-red.font-weight-semibold(v-else) {{ $t('disabled') }}
        .campaign-integration-card-name-details {{ $t('googleAnalytics') }}
  template(v-else)
    .campaign-integration-card-logo.mb-2
      img(:src="require('@/assets/admin/svg/analytics3.svg')")
    .font-size-1--125.text-center.text-color-mild-gray Google Analytics
    .font-size-0--875.text-center.text-color-success-green.font-weight-semibold(
      v-if="isAnalyticsEnabled"
    ) {{ $t('enabled') }}
    .font-size-0--875.text-center.text-color-error-red.font-weight-semibold(v-else) {{ $t('disabled') }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import { NEW_INTEGRATION_FLOW } from '@/utils/features';
  import domainInfos from '@/mixins/domainInfos';

  export default {
    mixins: [domainInfos],

    props: {
      campaign: {
        type: Object,
      },
      small: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapGetters(['hasAccountFeature']),
      isNewIntegrationFlow() {
        return this.hasAccountFeature(NEW_INTEGRATION_FLOW);
      },
    },
  };
</script>

<style lang="sass">
  .campaign-integration-card-analytics
    .campaign-integration-card-logo
      background-color: white
  .new-flow
    .campaign-integration-card-logo
      min-width: 4.5rem
      min-height: 4.5rem
</style>
