<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.getResponse.fields.campaignName') }}
        om-tooltip.pl-1
          span {{ $t('integrations.getResponse.tooltip.campaignName') }}
      .col-sm-8
        select.form-control(
          :class="{ 'is-invalid': $v.settings.campaignName.$error }"
          v-model="settings.campaignName"
        )
          option(:value="null") {{ $t('select') }}
          template(v-for="campaign in campaigns")
            option(:value="campaign.id") {{ campaign.name }}

    template(v-if="settings.campaignName")
      integration-binding(
        :type="globalIntegration.type"
        :bindings.sync="bindings"
        :fields.sync="fields"
      )
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        campaigns: [],
        fields: [],
        settings: {
          campaignName: null,
        },
      };
    },

    validations: {
      settings: {
        campaignName: {
          required,
        },
      },
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { campaigns, fields },
          },
        }) {
          this.campaigns = campaigns;
          this.fields = fields;
          this.$emit('loaded');
        },
      },
    },
  };
</script>
