<template lang="pug">
div
  .text-uppercase.font-weight-bold.mb-3 {{ $t('inputFieldBindings') }}
  .row.mb-3
    .col-5
      .col-form-label
        b {{ $t('dataFromOptimonk', { brand: brandName }) }}
    .col-7
      .col-form-label
        b {{ externalSystemLabel }}
        om-tooltip.pl-1(v-if="$te(`integrations.${type}.binding.tooltip`)")
          span(v-html="$t(`integrations.${type}.binding.tooltip`)")
  template(v-for="(binding, index) in dynamicBindings")
    bindingRow(
      ref="dynamicBindingRows"
      v-if="shouldDisplayBindingRow(binding)"
      :isNewFlow="false"
      :binding="binding"
      :index="index"
      :fields="fields"
      :type="type"
      :groupNames="groupNames"
    )
      i.fas.fa-info-circle.cursor-pointer
  .row(v-if="hasStatics")
    .col-12
      .binding-separator.font-weight-bold.my-3
        span {{ $t('inputFieldBindingsStaticTitle') }}
  .row.mb-3(v-if="hasStatics")
    template(v-if="!isShopify")
      .col-5
        .col-form-label
          b {{ $t('staticValue') }}
      .col-7
        .col-form-label
          b {{ externalSystemLabel }}
          om-tooltip.pl-1(v-if="$te(`integrations.${type}.binding.tooltip`)")
            span(v-html="$t(`integrations.${type}.binding.tooltip`)")
    template(v-else)
      .col-5
        .col-form-label
          b {{ externalSystemLabel }}
          om-tooltip.pl-1(v-if="$te(`integrations.${type}.binding.tooltip`)")
            span(v-html="$t(`integrations.${type}.binding.tooltip`)")
      .col-7
        .col-form-label
          b {{ $t('staticValue') }}
  template(v-if="hasStatics" v-for="(binding, index) in staticBindings")
    bindingRow(
      ref="staticBindingRows"
      :isNewFlow="false"
      :binding="binding"
      :index="index"
      :fields="fields"
      :type="type"
      @removeStaticRow="removeStaticRow"
    )
  //- This button is for dev only
  //- .row
    .col-4
      button.w-100.brand-btn.brand-btn-sm.brand-btn-primary-outline(@click="checkBindingRequirements()") Check binding
  .row(
    v-if="!isShopify && !oldCampaign && !isSmsBump && !isAttentive && !isPostscriptLegacy && !isSendGrid"
    :class="{ 'mt-3': hasStatics }"
  )
    .col-4
      om-button(secondary block @click="addNewStaticField()") {{ $t('addNewStaticField') }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import BindingRow from '@/components/IntegrationBindings/Row.vue';
  import { getConfigForIntegration, getIntegrationRequiredFields } from '@om/integrations';

  export default {
    components: { BindingRow },
    props: {
      type: { type: String },
      bindings: { type: Array, required: true },
      fields: { type: Array, default: () => [] },
      groupNames: { type: Array, default: () => [] },
      tags: { type: Array, default: () => [] },
    },
    computed: {
      ...mapGetters(['lastEditedData', 'brandName']),
      externalSystemLabel() {
        const key = `integrations.${this.type}.binding.title`;
        const name = this.$t(`integrations.${this.type}.name`);
        const translation = this.$t(key);
        // fallback is no translation is available
        return key !== translation ? translation : this.$t('fieldIdentifier', { name });
      },
      staticBindings() {
        return this.bindings.filter((item) => item.isFix);
      },
      oldCampaign() {
        return this.lastEditedData.version === 1;
      },
      shouldDisplayBindingRow() {
        return (binding) => {
          if (this.isConversio && ['email', 'firstname', 'lastname'].includes(binding.fieldId)) {
            return false;
          }
          if (this.isAweber && ['firstname', 'lastname'].includes(binding.fieldId)) {
            return false;
          }
          return true;
        };
      },
      hasStatics() {
        return !this.oldCampaign && this.staticBindings.length > 0;
      },
      dynamicBindings() {
        return this.bindings.filter((item) => !item.isFix);
      },
      isConversio() {
        return this.type === 'conversio';
      },
      isSmsBump() {
        return this.type === 'smsBump';
      },
      isPostscriptLegacy() {
        return this.type === 'postscriptLegacy';
      },
      isAttentive() {
        return this.type === 'attentive';
      },
      isShopify() {
        return this.type === 'shopifyCustomer';
      },
      isAweber() {
        return this.type === 'aWeber';
      },
      isSendGrid() {
        return this.type === 'sendGrid';
      },
      config() {
        return getConfigForIntegration(this.type);
      },
    },
    watch: {
      fields() {
        this.updateWithType();
      },
      bindings() {
        this.updateWithType();
        this.addTagsToStaticFields();
      },
    },
    mounted() {
      this.updateWithType();
    },
    methods: {
      checkBindingRequirements() {
        const requirements = getIntegrationRequiredFields(this.type);

        if (!requirements) return true;

        let result = true;
        requirements.forEach((requirement) => {
          const arrayFN = requirement[0] === 'or' ? 'some' : 'every';
          const fields = requirement.slice(1);

          result =
            result &&
            this.dynamicBindings[arrayFN](({ externalId }) => fields.includes(externalId));
        });

        return result;
      },
      addTagsToStaticFields() {
        this.tags.forEach((fieldId) => {
          const bind = this.bindings.find((item) => {
            const { externalId, externalType } = item;
            return externalType === '__TAG__' && externalId === fieldId;
          });

          if (!bind) {
            this.addNewStaticField({
              externalType: '__TAG__',
              externalId: fieldId,
            });
          }
        });
      },
      addNewStaticField(override = {}) {
        // eslint-disable-next-line
        this.bindings.push({
          isFix: true,
          fixValue: '',
          externalType: null,
          externalId: null,
          ...override,
        });
      },
      removeStaticRow({ binding }) {
        const index = this.bindings.indexOf(binding);
        // eslint-disable-next-line
        this.bindings.splice(index, 1);
      },
      updateWithType() {
        const first = this.fields[0];
        if (first && first.externalType) {
          this.bindings.forEach((binding, i) => {
            const field = this.fields.find((f) => f.id === binding.externalId);
            if (field) {
              // eslint-disable-next-line
              this.bindings[i].externalType = field.externalType;
            }
          });
          this.$emit('update:bindings', this.bindings);
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  .binding-separator
    position: relative
    z-index: 1
    &:before
      border-top: 2px solid #dfdfdf
      content: ""
      margin: 0 auto
      position: absolute
      top: 50%
      left: 0
      right: 0
      bottom: 0
      width: 95%
      z-index: -1
    span
      background: #fff
      padding: 0 1em 0 0
</style>
