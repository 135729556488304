<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
        om-tooltip.pl-1
          span(v-if="lists === false") {{ $t('integrations.hubSpot.tooltip.permission') }}
          span(v-else) {{ $t('integrations.hubSpot.tooltip.listName') }}
      .col-sm-8
        select#listName.form-control(v-model="settings.listId" :disabled="lists === false")
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}
  div(v-if="fields.length")
    integration-binding(
      :type="globalIntegration.type"
      :bindings.sync="bindings"
      :fields.sync="fields"
    )
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fields: [],
        settings: {
          listId: null,
        },
      };
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { lists, fields },
          },
        }) {
          this.lists = lists;
          this.fields = fields;
          this.$emit('loaded');
        },
      },
    },
  };
</script>
