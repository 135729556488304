<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-4
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
      .col-sm-8
        multiselect(
          v-model="listId"
          :options="lists"
          label="name"
          track-by="id"
          :multiple="false"
          :taggable="false"
          :show-labels="false"
          :placeholder="$t('selectAList')"
          :closeOnSelect="true"
        )
    integration-binding(
      :type="globalIntegration.type"
      :bindings.sync="bindings"
      :fields.sync="fields"
    )
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import integrationSetting from '@/mixins/integrationSetting';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fields: [],
        settings: {
          listId: null,
        },
      };
    },

    computed: {
      listId: {
        get() {
          const list = this.lists.find((l) => l.id === this.settings.listId);

          return list
            ? {
                id: this.settings.listId,
                name: list.name,
              }
            : null;
        },
        set(v) {
          if (v) {
            this.settings.listId = v.id;
          } else {
            this.settings.listId = null;
          }
        },
      },
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { lists, fields },
          },
        }) {
          this.lists = lists.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          this.fields = fields.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          this.$emit('loaded');
        },
      },
    },
  };
</script>
