<template lang="pug">
div
  .form-group.row.align-items-center(v-if="isNew")
    .col-sm-5.col-form-label
      label.mb-0(for="language") {{ $t('integrations.shopRenter.labels.listLanguage') }}
        om-tooltip.pl-1
          span(v-html="$t('integrations.shopRenter.tooltip.listLanguage')")
    .col-sm-7
      select#language.form-control(v-model="settings.language")
        option(:value="null") {{ $t('select') }}
        template(v-for="(lang, i) in languages")
          option(:value="lang.id") {{ lang.name }}
  integration-binding(
    v-if="isNew"
    :type="globalIntegration.type"
    :bindings.sync="bindings"
    :fields="fields"
  )
</template>

<script>
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import GET_SHOPRENTER_LANGUAGES from '@/graphql/GetShopRenterLanguages.gql';

  export default {
    components: { IntegrationBinding },
    mixins: [integrationSetting],
    data: () => ({
      languages: [],
      settings: {
        language: null,
      },
    }),
    computed: {
      isNew() {
        return (
          this.globalIntegration.data.version === 'new' || !!this.globalIntegration.data.apiUrl
        );
      },
      fields() {
        return [
          {
            name: 'firstname',
            id: 'firstname',
          },
          {
            name: 'lastname',
            id: 'lastname',
          },
          {
            name: 'phone',
            id: 'phone',
          },
          {
            name: 'email',
            id: 'email',
          },
        ];
      },
    },
    watch: {
      globalIntegration: {
        deep: true,
        handler() {
          this.$apollo.queries.languages.refetch();
        },
      },
    },
    apollo: {
      languages: {
        query: GET_SHOPRENTER_LANGUAGES,
        variables() {
          if (this.globalIntegration.data.transformed) {
            const { apiUrl, username, password } = this.globalIntegration.data;
            return { apiUrl, username, password };
          }
          return {
            integrationId: this.globalIntegration._id,
          };
        },
        result({ data: { languages } }) {
          this.languages = languages;
        },
      },
    },
    created() {
      this.$emit('loaded');
    },
  };
</script>
