export default (settings, bindings, brandName) => {
  if (settings.customFieldName && settings.customFieldName.length) {
    bindings.push({
      isFix: true,
      fixValue: settings.customFieldText || brandName,
      externalId: settings.customFieldName,
    });

    delete settings.customFieldName;
    delete settings.customFieldText;
  }
};
