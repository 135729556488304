<template lang="pug">
.row.align-items-center.mb-3(v-if="this.integration.type !== 'shopRenter'")
  .col-4
    .mb-3 {{ $t(`integrationFields.name`) }}:
    template(v-for="(value, fieldName, index) in integration.data")
      template(
        v-if="fieldName !== 'name' && !isOAuth && hasTranslation(integration.type, fieldName)"
      )
        .col-form-label(:class="marginBottom(index, integration)")
          span {{ $t(`integrationFields.${fieldName}`, { brand: brandName }) }}:
          template(v-if="hasTranslation(integration.type, fieldName)")
            om-tooltip.pl-1
              span(
                v-if="hasTranslation(integration.type, `${fieldName}WL`)"
                v-html="$t(`integrations.${integration.type}.tooltip.${fieldName}WL`, { brand: brandName })"
              )
              span(
                v-else
                v-html="$t(`integrations.${integration.type}.tooltip.${fieldName}`, { brand: brandName })"
              )

  .col-8
    .mb-3.global-integration-value-bigger-ellipsis(:title="integration.data.name") {{ integration.data.name }}
    template(v-for="(value, fieldName, index) in integration.data")
      .global-integration-value-bigger-ellipsis(
        v-if="fieldName !== 'name' && !isOAuth && hasTranslation(integration.type, fieldName)"
        :class="marginBottom(index, integration)"
        :title="integration.data[fieldName]"
      ) {{ integration.data[fieldName] }}
ShopRenterGlobal(v-else :global.sync="integration.data" :create="false")
  //- .col-1
  //-   .text-primary.cursor-pointer(v-if="!editMode" @click="editMode = true") {{ $t('edit') }}
  //-   .text-primary.cursor-pointer(v-else @click="saveGlobal") {{ $t('save') }}
</template>

<script>
  import EDIT_GLOBAL_INTEGRATION from '@/graphql/EditGlobalIntegration.gql';
  import { getConfigForIntegration } from '@om/integrations';
  import { mapGetters, mapState } from 'vuex';
  import ShopRenterGlobal from '@/components/IntegrationSettings/ShopRenterGlobal.vue';

  export default {
    components: { ShopRenterGlobal },
    props: {
      integration: {
        type: Object,
        required: true,
      },
      domain: { type: [String, null, undefined], default: null },
    },

    data() {
      return {
        editMode: false,
      };
    },

    computed: {
      ...mapState(['campaign']),
      ...mapGetters(['brandName', 'isActiveShopifyDomain']),
      isOAuth() {
        return getConfigForIntegration(this.integration.type).isOAuth;
      },
      hasOAuthField() {
        return (
          getConfigForIntegration(this.integration.type)?.oAuthFields &&
          getConfigForIntegration(this.integration.type)?.oAuthFields.length
        );
      },
      oAuthFields() {
        return getConfigForIntegration(this.integration.type)?.oAuthFields;
      },
      isShopifyOnly() {
        return !!getConfigForIntegration(this.integration.type)?.shopifyOnly;
      },
      activeShopify() {
        return this.domain && this.isActiveShopifyDomain(this.domain);
      },
    },

    methods: {
      saveGlobal() {
        this.$apollo
          .mutate({
            mutation: EDIT_GLOBAL_INTEGRATION,
            variables: {
              integrationId: this.integration._id,
              input: {
                type: this.integration.type,
                data: this.integration.data,
              },
            },
          })
          .then(({ data: { editIntegration } }) => {
            if (editIntegration.success) {
              this.$emit('refetchQuery', 'accountSettings');
              this.$notify({
                type: 'success',
                text: this.$t('notifications.saveSuccess'),
              });
              this.editMode = false;
            } else {
              this.$notify({
                type: 'error',
                text: this.$t('notifications.apiConnectError'),
              });
            }
          });
      },

      marginBottom(index, integration) {
        return index === Object.keys(integration.data).length - 1 ? 'mb-0' : 'mb-3';
      },

      hasTranslation(type, fieldName) {
        return this.$te(`integrations.${type}.tooltip.${fieldName}`);
      },
    },
  };
</script>
