import ALL_FIELDS from '@/graphql/AllFields.gql';
import ADD_CAMPAIGN_INTEGRATION from '@/graphql/AddCampaignIntegration.gql';
import UPDATE_CAMPAIGN_INTEGRATION from '@/graphql/UpdateCampaignIntegration.gql';
import { mapGetters } from 'vuex';
import * as integrationSettingMigrators from '../services/integrations/migrators/index';

const _canContainBrand = [
  'sourceFieldValue', // ActiveCampaign
  'sourceText', // ActOn, MooSend
  'customFieldText', // Automizy, MailerLite
  'sourceFieldValue', // Copernica, Marketo, Robly
  'sourceValue', // DotMailer, GetResponse, MailJet, MailUp, Mailigen
  'fieldValue', // Emarsys
  'sourceInputValue', // SalesForce, VerticalResponse, Soundest
];
// import _merge from 'lodash.merge'

export default {
  props: {
    globalIntegration: { type: Object, required: true },
    savedIntegration: { type: Object },
    saveBtnClicked: { type: Number, required: true },
  },

  computed: {
    ...mapGetters(['isSubUser', 'brandName']),
    isEditMode() {
      return this.savedIntegration && Object.keys(this.savedIntegration).length !== 0;
    },
  },

  data() {
    return {
      bindings: [
        { fieldId: 'url', name: this.$t('urlOfAppearance'), type: 'text', externalId: null },
        { fieldId: 'campaign_name', name: this.$t('campaignName'), type: 'text', externalId: null },
        {
          fieldId: 'variant_name',
          name: this.$t('integrationFlow.integrationModal.mapping.variantName'),
          type: 'text',
          externalId: null,
        },
      ],
    };
  },

  watch: {
    saveBtnClicked(newVal) {
      if (newVal) {
        if (this.customValidation && !this.customValidation()) {
          return;
        }

        if (!this.$v) {
          this.saveIntegration();
          return;
        }

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
        } else {
          this.saveIntegration();
        }
      }
    },
  },

  methods: {
    saveIntegration() {
      const settings = [];
      if (this.settings && Object.keys(this.settings).length) {
        for (const key of Object.keys(this.settings)) {
          let value = this.settings[key];
          if (_canContainBrand.includes(key) && value === '') value = this.brandName; // SubUser whitelabel hack
          settings.push({ key, value });
        }
      }

      if (this.isEditMode) {
        this.$apollo
          .mutate({
            mutation: UPDATE_CAMPAIGN_INTEGRATION,
            variables: {
              campaignId: this.$route.params.id,
              input: {
                _id: this.savedIntegration._id,
                id: this.globalIntegration._id,
                settings,
                bindings: this.bindings,
              },
            },
          })
          .then(
            this.$emit('saved', {
              _id: this.savedIntegration._id,
              id: this.globalIntegration._id,
              global: this.globalIntegration,
              settings,
              bindings: this.bindings,
            }),
          );
      } else {
        this.$apollo
          .mutate({
            mutation: ADD_CAMPAIGN_INTEGRATION,
            variables: {
              campaignId: this.$route.params.id,
              input: {
                id: this.globalIntegration._id,
                settings,
                bindings: this.bindings,
              },
            },
          })
          .then(({ data: { addCampaignIntegration } }) => {
            this.$emit('saved', {
              _id: addCampaignIntegration,
              id: this.globalIntegration._id,
              global: this.globalIntegration,
              settings,
              bindings: this.bindings,
            });
          });
      }
    },
  },

  created() {
    this.$apollo
      .query({
        query: ALL_FIELDS,
        variables: {
          campaignId: parseInt(this.$route.params.id, 10),
        },
      })
      .then(({ data: { allFields } }) => {
        const customFields = allFields.map((f) => {
          return {
            fieldId: f.customId,
            name: f.name,
            type: f.type,
            externalId: null,
          };
        });

        this.bindings = ['smsBump', 'postscriptLegacy'].includes(this.globalIntegration.type)
          ? customFields
          : this.bindings.concat(customFields);

        if (this.isEditMode) {
          if (this.settings) {
            for (const setting of this.savedIntegration.settings) {
              this.settings[setting.key] = setting.value;
            }
          }

          const staticFields = [];

          if (this.savedIntegration.bindings && this.savedIntegration.bindings.length) {
            for (const binding of this.savedIntegration.bindings) {
              if (binding.isFix) {
                staticFields.push(binding);
              } else {
                const index = this.bindings.findIndex((b) => b.fieldId === binding.fieldId);
                if (index > -1) {
                  this.bindings[index].externalId = binding.externalId;
                } else {
                  console.log('warning, binding not found', binding.fieldId);
                }
              }
            }
          }

          this.bindings = this.bindings.concat(staticFields);
        }

        const migrator = integrationSettingMigrators.default[this.globalIntegration.type];

        if (migrator) {
          migrator(this.settings, this.bindings, this.brandName);
        }
      });
  },
};
