<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
        om-tooltip.pl-1(v-if="reqFieldsOnList.length" primary)
          div
            | {{ $t('integrations.mailChimp.tooltip.reqFields') }}
            template(v-for="(reqField, index) in reqFields")
              span.font-weight-bold(v-if="index !== reqFields.length - 1") {{ fields.find((f) => f.id === reqField).name + ',' }}
              span.font-weight-bold(v-else) {{ ' ' + fields.find((f) => f.id === reqField).name }}
          div {{ $t('integrations.mailChimp.tooltip.reqFields1') }}
      .col-sm-8
        select#listName.form-control(
          :class="{ 'is-invalid': $v.settings.listId.$error }"
          v-model="settings.listId"
        )
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label(for="doubleOptin") {{ $t('integrations.mailChimp.fields.doubleOptin') }}
      .col-sm-8
        toggle-button.mb-0(:width="50" :height="24" v-model="settings.doubleOptin" :sync="true")
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label(for="groups") {{ $t('integrations.mailChimp.fields.groups') }}
        om-tooltip.pl-1
          div(v-html="$t('integrations.mailChimp.tooltip.groups')")
      .col-sm-8
        multiselect(
          v-model="settings.hiddenListGroupId"
          :options="groups"
          :multiple="true"
          label="value"
          track-by="id"
          :taggable="false"
          :show-labels="false"
          :placeholder="$t('itemSelected', { num: settings.hiddenListGroupId.length })"
          :closeOnSelect="false"
          :disabled="!settings.listId"
        )
          template(slot="clear" slot-scope="props")
            .multiselect__clear {{ $t('itemSelected', { num: settings.hiddenListGroupId.length }) }}
          template(slot="tag" slot-scope="props")
            span
  div(v-if="$apollo.queries.mailChimpData.loading")
    loading-logo
  div(v-else-if="fields.length")
    integration-binding(
      :type="globalIntegration.type"
      :bindings.sync="bindings"
      :fields.sync="fields"
      :groupNames="groupNames"
    )
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import GET_MAILCHIMP_GROUPS_AND_FIELDS from '@/graphql/GetMailChimpGroupsAndFields.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        groups: [],
        fields: [],
        reqFields: [],
        groupNames: [],
        settings: {
          listId: null,
          doubleOptin: false,
          hiddenListGroupId: [],
        },
      };
    },

    validations: {
      settings: {
        listId: {
          required,
        },
      },
    },
    computed: {
      reqFieldsOnList() {
        return this.reqFields.filter((e) => this.fields.find((f) => f.name === e.name));
      },
    },
    apollo: {
      mailChimpData: {
        query: GET_MAILCHIMP_GROUPS_AND_FIELDS,
        variables() {
          return {
            integrationId: this.globalIntegration._id,
            listId: this.settings.listId,
          };
        },
        result({ data: { mailChimpData } }) {
          this.groups = mailChimpData.groups;
          this.fields = mailChimpData.fields;
          this.reqFields = mailChimpData.reqFields;
          this.groupNames = mailChimpData.groupNames;

          if (this.settings.hiddenListGroupId.length) {
            const onlyId = typeof this.settings.hiddenListGroupId === 'string';
            const selectedGroups = onlyId
              ? [{ id: this.settings.hiddenListGroupId }]
              : this.settings.hiddenListGroupId;
            this.settings.hiddenListGroupId = this.groups.filter((e) => {
              return selectedGroups.find((i) => i.id === e.id) != null;
            });
          } else {
            this.settings.hiddenListGroupId = [];
          }
        },
      },
    },

    watch: {
      'settings.listId': function (n, old) {
        if (old !== null) {
          this.bindings.forEach((b) => {
            b.externalId = null;
          });
        }
      },
    },

    created() {
      this.$apollo
        .query({
          query: GET_INTEGRATION_LISTS,
          variables: {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          },
        })
        .then(({ data: { lists } }) => {
          this.lists = lists;

          this.$emit('loaded');
        });
    },
  };
</script>
