<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('listName') }}:
        om-tooltip.pl-1
          span {{ $t('integrations.actOn.tooltip.listId') }}
      .col-sm-8
        select.form-control(
          :class="{ 'is-invalid': $v.settings.listId.$error }"
          v-model="settings.listId"
          @change="fetchFields(settings.listId)"
        )
          option(:value="null") {{ $t('select') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

    template(
      v-for="fieldName in ['emailField', 'firstNameField', 'lastNameField', 'sourceInputName']"
    )
      .row.align-items-center.mb-2
        .col-sm-4.col-form-label
          label.mb-0 {{ $t(`integrations.actOn.fields.${fieldName}`, { brand: brandName }) }}
          om-tooltip.pl-1
            span {{ $t(`integrations.actOn.tooltip.${fieldName}`, { brand: brandName }) }}
        .col-sm-8
          select.form-control(:disabled="!settings.listId" v-model="settings[fieldName]")
            option(:value="null") {{ $t('select') }}
            template(v-for="field in fields")
              option(:value="field") {{ field }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.actOn.fields.sourceText', { brand: brandName }) }}
        om-tooltip.pl-1
          span {{ $t('integrations.actOn.tooltip.sourceText', { brand: brandName }) }}
      .col-sm-8
        input.form-control(v-model="settings.sourceText")
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import GET_INTEGRATION_FIELDS from '@/graphql/GetIntegrationFields.gql';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fields: [],
        settings: {
          listId: null,
          emailField: null,
          firstNameField: null,
          lastNameField: null,
          sourceInputName: null,
          sourceText: '',
        },
      };
    },

    created() {
      const self = this;

      this.fetchLists().then(() => {
        if (self.settings.listId) {
          this.fetchFields(self.settings.listId);
        } else {
          this.$emit('loaded');
        }
      });
    },

    methods: {
      fetchLists() {
        const self = this;
        const req = this.$apollo.query({
          query: GET_INTEGRATION_LISTS,
          variables: {
            integrationType: self.globalIntegration.type,
            integrationId: self.globalIntegration._id,
          },
        });

        req.then(({ data: { lists } }) => {
          this.lists = lists;
        });

        return req;
      },

      fetchFields(listId) {
        const self = this;

        const req = this.$apollo.query({
          query: GET_INTEGRATION_FIELDS,
          variables: {
            integrationType: self.globalIntegration.type,
            integrationId: self.globalIntegration._id,
            listId,
          },
        });

        req.then(({ data: { fields } }) => {
          this.fields = fields;
          this.$emit('loaded');
        });

        return req;
      },
    },

    validations: {
      settings: {
        listId: {
          required,
        },
      },
    },
  };
</script>
