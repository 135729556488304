<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
      .col-sm-8
        select#listName.form-control(
          :class="{ 'is-invalid': $v.settings.listId.$error }"
          v-model="settings.listId"
        )
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.cleverReach.fields.formId') }}
      .col-sm-8
        select.form-control(:disabled="!settings.listId" v-model="settings.formId")
          option(:value="null") {{ $t('select') }}
          template(v-for="form in forms")
            option(:value="form.id") {{ form.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label(for="doubleOptin") {{ $t('integrations.cleverReach.fields.optIn') }}
      .col-sm-8
        toggle-button.mb-0(:width="50" :height="24" v-model="settings.optIn" :sync="true")
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import GET_CLEVER_REACH_FORMS from '@/graphql/GetCleverReachForms.gql';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        forms: [],
        settings: {
          listId: null,
          formId: null,
          optIn: false,
        },
      };
    },

    validations: {
      settings: {
        listId: {
          required,
        },
      },
    },

    apollo: {
      lists: {
        query: GET_INTEGRATION_LISTS,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result() {
          this.$emit('loaded');
        },
      },

      forms: {
        query: GET_CLEVER_REACH_FORMS,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
            groupId: this.settings.listId,
          };
        },
      },
    },
  };
</script>
