export default (settings, bindings, brandName) => {
  if (settings.sourceField && settings.sourceText !== '0') {
    bindings.push({
      isFix: true,
      fixValue: settings.sourceText || brandName,
      externalId: settings.sourceField,
    });

    delete settings.sourceField;
    delete settings.sourceText;
  }
};
