<template lang="pug">
div(v-if="hasAppForShopUrl")
  .form-group.row.align-items-center
    .col-sm-5.col-form-label
      label.mb-0(for="account") {{ $t('integrations.shopRenter.labels.account') }}:
    .col-sm-7
      select#account.form-control(v-if="create" v-model="selectedShop")
        template(v-for="(app, i) in activeApps")
          option(:value="app.shopname" :selected="i === 0") {{ getName(app) }}
      span(v-else) {{ global.identifier }}
div(v-else)
  integration-row(
    type="shopRenter"
    :fieldName="field"
    :integrationData.sync="global"
    v-for="field in oldAuthFields"
    :key="field"
    :edit="create"
  )
</template>

<script>
  import { mapGetters } from 'vuex';
  import IntegrationRow from '@/components/IntegrationRow.vue';

  export default {
    components: { IntegrationRow },
    props: {
      global: { type: Object, required: true },
      create: { type: Boolean, default: false },
      campaignDomain: { type: String },
    },
    data: () => ({
      selectedShop: null,
      version: 'new',
      transformed: false,
      oldAuthFields: ['shopUrl', 'username', 'password'],
    }),
    computed: {
      ...mapGetters(['shopRenterAppSettings']),
      activeApps() {
        return this.shopRenterAppSettings.filter((a) => a.active && a.domains && a.domains.length);
      },
      hasAppForShopUrl() {
        if (this.global.apiUrl) return true;
        let result = false;
        this.activeApps.forEach(({ domains }) => {
          let url;
          try {
            url = new URL(global.shopUrl);
          } catch (e) {
            // error
          }
          if (url) {
            const hasDomain = domains.find(({ domain }) => domain === url.hostname);
            if (hasDomain) result = true;
          }
        });
        return result;
      },
    },
    watch: {
      selectedShop(n) {
        if (n) {
          const app = this.shopRenterAppSettings.find((s) => s.shopname === this.selectedShop);
          const { username, password } = app;

          this.$emit('update:global', {
            ...this.global,
            identifier: this.getName(app),
            apiUrl: `https://${app.shopname}.api.shoprenter.hu`,
            username,
            password,
            transformed: this.transformed || undefined,
            version: this.version,
          });
        }
      },
    },
    mounted() {
      this.tryTransform();
      if (this.version === 'new' && this.create) this.init();
    },
    created() {
      this.$emit('loaded');
    },
    methods: {
      getPrimaryDomain(app) {
        const primary = app.domains.find((d) => d.primary);
        return primary.domain;
      },
      getName(app) {
        return `${app.shopname} (${this.getPrimaryDomain(app)})`;
      },
      tryTransform() {
        if (this.global.shopUrl) {
          try {
            const url = new URL(this.global.shopUrl);
            const app = this.activeApps.find(
              (a) =>
                !!a.domains.find((d) => {
                  return url.hostname === d.domain;
                }),
            );
            if (app) {
              delete this.global.shopUrl;
              this.selectedShop = app.shopname;
              this.transformed = true;
            } else {
              this.version = 'old';
            }
          } catch (e) {
            this.version = 'old';
          }
        }
      },
      init() {
        if (!this.activeApps.length) return;
        let app;
        if (this.campaignDomain) {
          app = this.activeApps.find(
            (a) => !!a.domains.find((d) => d.domain === this.campaignDomain),
          );
        }
        if (!app) app = this.activeApps[0];
        this.selectedShop = app.shopname;
      },
    },
  };
</script>
