export default (settings, bindings, brandName) => {
  if (settings.emailField && settings.emailField !== '0') {
    const fieldIndex = bindings.findIndex((binding) => {
      return binding.fieldId === 'email';
    });
    if (fieldIndex > -1) {
      bindings[fieldIndex].externalId = settings.emailField;
    }

    delete settings.emailField;
  }

  if (settings.firstnameField && settings.firstnameField !== '0') {
    const fieldIndex = bindings.findIndex((binding) => {
      return binding.fieldId === 'firstname';
    });
    if (fieldIndex > -1) {
      bindings[fieldIndex].externalId = settings.firstnameField;
    }

    delete settings.firstnameField;
  }

  if (settings.lastnameField && settings.lastnameField !== '0') {
    const fieldIndex = bindings.findIndex((binding) => {
      return binding.fieldId === 'lastname';
    });
    if (fieldIndex > -1) {
      bindings[fieldIndex].externalId = settings.lastnameField;
    }

    delete settings.lastnameField;
  }

  if (settings.sourceFieldName && settings.sourceFieldName !== '0') {
    bindings.push({
      isFix: true,
      fixValue: settings.sourceFieldValue || brandName,
      externalId: settings.sourceFieldName,
    });

    delete settings.sourceFieldName;
    delete settings.sourceFieldValue;
  }
};
