<template lang="pug">
integration-binding(
  ref="binding"
  :type="globalIntegration.type"
  :tags="tags"
  :bindings.sync="bindings"
  :fields="fields"
)
</template>

<script>
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },

    mixins: [integrationSetting],

    props: {
      tags: { type: [Array], default: () => [] },
    },
    data() {
      return {
        bindings: [
          {
            fieldId: 'campaign_name',
            name: this.$t('campaignName'),
            type: 'text',
            externalId: null,
          },
        ],
      };
    },
    computed: {
      fields() {
        return [
          {
            name: 'first_name',
            id: 'first_name',
          },
          {
            name: 'last_name',
            id: 'last_name',
          },
          {
            name: 'email',
            id: 'email',
            minimalRequired: true,
          },
          {
            name: 'phone',
            id: 'phone',
            minimalRequired: true,
          },
        ];
      },
    },
    created() {
      this.$emit('loaded');
    },
    methods: {
      checkBinding() {
        return this.$refs.binding.checkBindingRequirements();
      },
    },
  };
</script>
