export default (settings, bindings, brandName) => {
  if (settings.firstnameField && settings.firstnameField.length) {
    const index = bindings.findIndex((b) => b.fieldId === 'firstname');
    if (index !== -1) {
      bindings[index].externalId = settings.firstnameField;
    }
  }

  delete settings.firstnameField;

  if (settings.lastnameField && settings.lastnameField.length) {
    const index = bindings.findIndex((b) => b.fieldId === 'lastname');
    if (index !== -1) {
      bindings[index].externalId = settings.lastnameField;
    }
  }

  delete settings.lastnameField;

  if (settings.sourceField && settings.sourceValue.length) {
    bindings.push({
      isFix: true,
      fixValue: settings.sourceValue || brandName,
      externalId: settings.sourceField,
    });

    delete settings.sourceField;
    delete settings.sourceValue;
  }
};
