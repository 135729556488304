<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
        om-tooltip.pl-1
          span(v-html="$t('integrations.IContact.tooltip.listId')")
      .col-sm-8
        select.form-control(v-model="settings.listId")
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}
  .row.align-items-center.mb-2
    .col-sm-4.col-form-label
      label.mb-0 {{ $t('integrations.IContact.fields.sourceInputName', { brand: brandName }) }}
      om-tooltip.pl-1
        span(v-html="$t('integrations.IContact.tooltip.sourceInputName', { brand: brandName })")
    .col-sm-8
      input.form-control(v-model="settings.sourceInputName")
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        settings: {
          listId: null,
          sourceInputName: '',
        },
      };
    },

    apollo: {
      lists: {
        query: GET_INTEGRATION_LISTS,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result() {
          this.$emit('loaded');
        },
      },
    },
  };
</script>
