<template lang="pug">
om-modal(name="shopify-remove" :width="420" :scrollable="true")
  template(slot="modal-header")
    .d-flex.align-items-center
      .flex-grow-1.font-weight-bold {{ $t('shopifyRemoveModal.title') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('shopify-remove')")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    .mb-2 {{ $t('shopifyRemoveModal.lead') }}
  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button.mr-3(ghost @click="cancel") {{ $t('cancel') }}
      om-button(primary @click="addIntegration") {{ $t('newIntegration') }}
</template>

<script>
  export default {
    methods: {
      addIntegration() {
        this.$modal.hide('shopify-remove');
        this.$modal.show('new-campaign-integration');
      },
      cancel() {
        this.$modal.hide('shopify-remove');
      },
    },
  };
</script>

<style lang="sass" scoped>
  .brand-modal .brand-modal-light .brand-modal-header
    border-bottom: 1px solid #e6e6e6
</style>
