<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.mailWizz.fields.updateExisting') }}
      .col-sm-8
        toggle-button.mb-0(:width="50" :height="24" v-model="settings.updateExisting" :sync="true")

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('listName') }}
      .col-sm-8
        select.form-control(
          :class="{ 'is-invalid': $v.settings.listId.$error }"
          v-model="settings.listId"
          @change="resetFields"
        )
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

  integration-binding(
    v-if="settings.listId"
    :type="globalIntegration.type"
    :bindings.sync="bindings"
    :fields.sync="fields"
  )
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import GET_INTEGRATION_FIELDS from '@/graphql/GetIntegrationFields.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fields: [],
        settings: {
          updateExisting: false,
          listId: null,
          firstName: null,
          lastName: null,
          hiddenInputName: null,
          hiddenInputValue: '',
        },
      };
    },

    validations: {
      settings: {
        listId: {
          required,
        },
      },
    },

    methods: {
      resetFields() {
        this.settings.firstName = null;
        this.settings.lastName = null;
        this.settings.hiddenInputName = null;
      },
    },

    apollo: {
      lists: {
        query: GET_INTEGRATION_LISTS,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({ data: { lists } }) {
          this.lists = lists.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          this.$emit('loaded');
        },
      },

      fields: {
        query: GET_INTEGRATION_FIELDS,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
            listId: this.settings.listId,
          };
        },
        result({ data: { fields } }) {
          this.fields = fields.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
        },
      },
    },
  };
</script>
