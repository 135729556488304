import marketo from './marketo';
import mailerLite from './mailerLite';
import activeCampaign from './activeCampaign';
import moosend from './moosend';
import mailjet from './mailjet';
import copernica from './copernica';
import maileon from './maileon';
import dotmailer from './dotmailer';
import mailWizz from './mailWizz';
import getResponse from './getResponse';
import mailigen from './mailigen';
import salesforce from './salesforce';

export default {
  marketo,
  mailerLite,
  activeCampaign,
  moosend,
  mailjet,
  copernica,
  maileon,
  dotmailer,
  mailWizz,
  getResponse,
  mailigen,
  salesforce,
};
