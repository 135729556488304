import { render, staticRenderFns } from "./NotifyMe.vue?vue&type=template&id=5ab0afd5&scoped=true&lang=pug&"
import script from "./NotifyMe.vue?vue&type=script&lang=js&"
export * from "./NotifyMe.vue?vue&type=script&lang=js&"
import style0 from "./NotifyMe.vue?vue&type=style&index=0&id=5ab0afd5&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ab0afd5",
  null
  
)

export default component.exports