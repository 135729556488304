<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('listName') }}
        om-tooltip.pl-1
          span {{ $t('integrations.robly.tooltip.listId') }}
      .col-sm-8
        select.form-control(v-model="settings.listId")
          option(:value="null") {{ $t('select') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.robly.fields.sourceFieldName') }}
        om-tooltip.pl-1
          span {{ $t('integrations.robly.tooltip.sourceFieldName', { brand: brandName }) }}
      .col-sm-8
        select.form-control(v-model="settings.sourceFieldName")
          option(:value="null") {{ $t('select') }}
          template(v-for="field in fields")
            option(:value="field.id") {{ field.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.robly.fields.sourceFieldValue') }}
        om-tooltip.pl-1
          span {{ $t('integrations.robly.tooltip.sourceFieldValue', { brand: brandName }) }}
      .col-sm-8
        input.form-control(v-model="settings.sourceFieldValue")

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.robly.fields.doubleOptin') }}
      .col-sm-8
        toggle-button.mb-0(:width="50" :height="24" v-model="settings.doubleOptin" :sync="true")

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.robly.fields.welcomeEmail') }}
        om-tooltip.pl-1
          span {{ $t('integrations.robly.tooltip.welcomeEmail') }}
      .col-sm-8
        toggle-button.mb-0(:width="50" :height="24" v-model="settings.welcomeEmail" :sync="true")

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.robly.fields.includeAutoresponder') }}
        om-tooltip.pl-1
          span {{ $t('integrations.robly.tooltip.includeAutoresponder') }}
      .col-sm-8
        toggle-button.mb-0(
          :width="50"
          :height="24"
          v-model="settings.includeAutoresponder"
          :sync="true"
        )
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fields: [],
        settings: {
          listId: null,
          sourceFieldName: null,
          sourceFieldValue: '',
          doubleOptin: false,
          welcomeEmail: false,
          includeAutoresponder: false,
        },
      };
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { lists, fields },
          },
        }) {
          this.lists = lists;
          this.fields = fields;
          this.$emit('loaded');
        },
      },
    },
  };
</script>
