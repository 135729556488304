<template lang="pug">
om-modal(name="integration-remove" :width="700" @beforeOpen="beforeOpen")
  template(slot="modal-header")
    span
  template(slot="modal-body")
    div {{ $t('globalIntegrationRemove') }}
    ul.my-3.global-integration-remove-overflow
      template(v-for="campaign in campaigns")
        li {{ campaign }}
    div {{ $t('areYouSure') }}
  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button(secondary large @click="$modal.hide('integration-remove')") {{ $t('no') }}
      om-button.ml-auto(large primary @click="remove") {{ $t('yes') }}
</template>
<script>
  import REMOVE_GLOBAL_INTEGRATION from '@/graphql/RemoveGlobalIntegration.gql';

  export default {
    data() {
      return {
        campaigns: [],
        integrationId: null,
      };
    },

    methods: {
      beforeOpen(event) {
        this.campaigns = event.params.campaigns;
        this.integrationId = event.params.integrationId;
      },

      remove() {
        this.$apollo
          .mutate({
            mutation: REMOVE_GLOBAL_INTEGRATION,
            variables: {
              integrationId: this.integrationId,
            },
          })
          .then(() => {
            this.$emit('refetchQuery', 'accountSettings');
            this.$emit('refetchQuery', 'campaignSettings');
            this.$modal.hide('integration-remove');
          });
      },
    },
  };
</script>
<style lang="sass">
  .global-integration-remove-overflow
    overflow: auto
    height: 12rem
</style>
