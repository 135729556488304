<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
      .col-sm-8
        select#listName.form-control(v-model="settings.listId")
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}
    integration-binding(
      :type="globalIntegration.type"
      :bindings.sync="bindings"
      :fields.sync="fields"
    )
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fields: [],
        settings: {
          listId: null,
        },
      };
    },

    created() {
      this.$apollo
        .query({
          query: GET_INTEGRATION_DATA,
          variables: {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          },
        })
        .then(
          ({
            data: {
              integrationData: { fields, lists },
            },
          }) => {
            this.fields = fields;
            this.lists = lists;
            this.$emit('loaded');
          },
        );
    },
  };
</script>
