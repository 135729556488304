<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="form") {{ $t('integrations.convertKit.fields.form') }}
        om-tooltip.pl-1
          span {{ $t('integrations.convertKit.tooltip.form') }}
      .col-sm-8
        select#form.form-control(
          :class="{ 'is-invalid': $v.settings.formId.$error }"
          v-model="settings.formId"
        )
          option(:value="null") {{ $t('select') }}
          template(v-for="form in forms")
            option(:value="form.id") {{ form.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="course") {{ $t('integrations.convertKit.fields.course') }}
        om-tooltip.pl-1
          span {{ $t('integrations.convertKit.tooltip.course') }}
      .col-sm-8
        select#course.form-control(v-model="settings.courseId")
          option(:value="null") {{ $t('select') }}
          template(v-for="course in courses")
            option(:value="course.id") {{ course.name }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="tag") {{ $t('integrations.convertKit.fields.tag') }}
        om-tooltip.pl-1
          span {{ $t('integrations.convertKit.tooltip.tag') }}
      .col-sm-8
        select#tag.form-control(v-model="settings.tagId")
          option(:value="null") {{ $t('select') }}
          template(v-for="tag in tags")
            option(:value="tag.id") {{ tag.name }}
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        forms: [],
        courses: [],
        tags: [],
        settings: {
          formId: null,
          courseId: null,
          tagId: null,
        },
      };
    },

    validations: {
      settings: {
        formId: {
          required,
        },
      },
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { forms, courses, tags },
          },
        }) {
          this.forms = forms;
          this.courses = courses;
          this.tags = tags;
          this.$emit('loaded');
        },
      },
    },
  };
</script>
