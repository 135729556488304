<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.infusionSoft.fields.doubleOptin') }}
      .col-sm-8
        toggle-button.mb-0(:width="50" :height="24" v-model="settings.doubleOptin" :sync="true")

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.infusionSoft.fields.tags') }}
        om-tooltip.pl-1
          span {{ $t('integrations.infusionSoft.tooltip.tags') }}
      .col-sm-8
        multiselect(
          v-model="selectedTags"
          :options="tags"
          :multiple="true"
          label="name"
          track-by="id"
          :taggable="false"
          :show-labels="false"
          :placeholder="$t('itemSelected', { num: selectedTags.length })"
          :closeOnSelect="false"
        )
          template(slot="clear" slot-scope="props")
            .multiselect__clear {{ $t('itemSelected', { num: selectedTags.length }) }}
          template(slot="tag" slot-scope="props")
            span
  integration-binding(:type="globalIntegration.type" :bindings.sync="bindings")
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        tags: [],
        selectedTags: [],
        settings: {
          doubleOptin: false,
          groupId: '',
        },
      };
    },

    watch: {
      selectedTags(tags) {
        this.settings.groupId = tags.map((t) => t.id).join('|');
      },
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { tags },
          },
        }) {
          this.tags = tags;

          if (this.settings.groupId) {
            const groupIds = this.settings.groupId.split('|');
            this.selectedTags = this.tags.filter((t) => groupIds.includes(t.id.toString()));
          } else {
            this.settings.groupId = '';
          }

          this.$emit('loaded');
        },
      },
    },
  };
</script>
