<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0 {{ $t('integrations.attentiveV2.fields.signUpSourceId') }}
        om-tooltip.pl-1
          span(v-html="$t(`integrations.attentiveV2.tooltip.signUpSourceId`)")
      .col-sm-8
        input#signUpSourceId.form-control(
          :class="{ 'is-invalid': $v.settings.signUpSourceId.$error }"
          v-model="settings.signUpSourceId"
        )
  integration-binding(
    v-if="bindings.some((item) => ['phoneNumber', 'email'].includes(item.type))"
    :type="globalIntegration.type"
    :bindings.sync="getRelevantBindings"
    :fields="fields"
  )
  .alert.alert-danger(v-if="!isValid") {{ $t('integrations.attentiveV2.noPhoneBinding') }}
</template>

<script>
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],
    data() {
      return {
        isValid: true,
        settings: {
          signUpSourceId: '',
        },
      };
    },

    validations: {
      settings: {
        signUpSourceId: {
          required,
        },
      },
    },
    computed: {
      fields() {
        return [
          {
            name: 'phone',
            id: 'phone',
            required: true,
          },
          {
            name: 'email',
            id: 'email',
            required: false,
          },
        ];
      },
      getRelevantBindings() {
        return this.bindings.filter((item) => item.type === 'phoneNumber' || item.type === 'email');
      },
    },
    created() {
      this.$emit('loaded');
    },
    methods: {
      customValidation() {
        this.isValid = this.bindings.some(
          (item) => ['phoneNumber', 'email'].includes(item.type) && item.externalId,
        );

        return this.isValid;
      },
    },
  };
</script>
