<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.maileon.fields.doubleOptin') }}
        om-tooltip.pl-1
          span {{ $t('integrations.maileon.tooltip.doubleOptin', { brand: brandName }) }}
      .col-sm-8
        select.form-control(v-model="settings.doubleOptin")
          option(value="1") {{ 'none' }}
          option(value="2") {{ 'single opt-in' }}
          option(value="3") {{ 'confirmed opt-in' }}
          option(value="4") {{ 'double opt-in' }}
          option(value="5") {{ 'double opt-in plus' }}
          option(value="6") {{ 'other' }}
      .col-sm-4.col-form-label
        label {{ $t('integrations.maileon.fields.sendDoi') }}
      .col-sm-8
        input.form-checkbox-input(type="checkbox" v-model="settings.sendDoi")
      .col-sm-4.col-form-label
        label {{ $t('integrations.maileon.fields.doiKey') }}
      .col-sm-8
        input.form-control(type="text" v-model="settings.doiKey")
    integration-binding(
      :type="globalIntegration.type"
      :bindings.sync="bindings"
      :fields.sync="fields"
    )
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        fields: [],
        settings: {
          doubleOptin: 1,
          customFieldInput: null,
          customFieldValue: '',
          sourceUrlFieldInput: null,
          sendDoi: false,
          doiKey: '',
        },
      };
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({
          data: {
            integrationData: { fields },
          },
        }) {
          this.fields = fields;
          this.$emit('loaded');
        },
      },
    },
  };
</script>
