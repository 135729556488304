<template lang="pug">
transition-group(name="fade" tag="div")
  .campaign-integration-card.campaign-integration-card-analytics.new-flow.d-flex(
    v-if="recommendedIntegrationsState !== RECOMMENDATION_STATES.NONE"
    :key="index"
    @mouseenter="hover = index"
    @mouseleave="hover = false"
  )
    .d-flex.justify-content-start.align-items-center
      .campaign-integration-card-logo.new-logo(:style="{ background: getBackgroundColor() }")
        integration-logo(:type="type" justImage)
      .d-flex.justify-content-start.align-items-center
        .integration-details
          .integration-name {{ $t(`integrations.${type}.name`) }}
          .integration-state-info {{ recommendationStateInfo }}
        button-wrapper.mr-3(
          :type="type"
          :campaignId="campaignId"
          :recommendationState="recommendedIntegrationsState"
        )
    .d-flex.justify-content-start.align-items-center.action-button(
      :class="{ 'is-hovered': hover !== index }"
    )
      i.fas.fa-times(@click.stop="rejectRecommendation()")
</template>

<script>
  import REJECT_RECOMMENDED_INTEGRATION from '@/graphql/RejectRecommendedIntegration.gql';
  import GET_RECOMMENDED_INTEGRATIONS_STATE from '@/graphql/GetRecommendedIntegrationsState.gql';
  import IntegrationLogo from '@/components/IntegrationLogo.vue';
  import ButtonWrapper from '@/components/CampaignSettings/RecommendedIntegrations/ButtonWrapper.vue';
  import { getIntegrationColors, RECOMMENDATION_STATES } from '@om/integrations';

  export default {
    components: {
      IntegrationLogo,
      ButtonWrapper,
    },
    props: {
      campaignId: {
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        RECOMMENDATION_STATES,
        hover: false,
        recommendedIntegrationsState: null,
      };
    },

    computed: {
      recommendationStateInfo() {
        return this.$t(
          `integrationRecommendationState.${this.recommendedIntegrationsState?.toLowerCase()}`,
          { name: this.$t(`integrations.${this.type}.name`) },
        );
      },
    },

    async created() {
      await this.fetchRecommendedIntegrationsState();
    },

    methods: {
      async fetchRecommendedIntegrationsState() {
        try {
          const {
            data: { recommendedIntegrationsState },
          } = await this.$apollo.query({
            query: GET_RECOMMENDED_INTEGRATIONS_STATE,
            variables: {
              integrationType: this.type,
              campaignId: this.campaignId,
            },
          });
          this.recommendedIntegrationsState = recommendedIntegrationsState;
        } catch (error) {
          console.warn('Unable to fetch recommended integrations state', error);
        }
      },

      getBackgroundColor() {
        return getIntegrationColors(this.type);
      },

      async rejectRecommendation() {
        try {
          const { data: rejectRecommendedIntegration } = await this.$apollo.mutate({
            mutation: REJECT_RECOMMENDED_INTEGRATION,
            variables: {
              campaignId: this.campaignId,
              integrationType: this.type,
            },
          });
          if (rejectRecommendedIntegration) {
            this.$notify({
              type: 'success',
              title: this.$t('rejectRecommendedIntegration.success.title'),
              text: this.$t('rejectRecommendedIntegration.success.text'),
            });
            this.$emit('fetchRecommendedIntegrations');
            this.$emit('refetchQuery', 'campaignSettings');
          } else {
            this.$notify({
              type: 'error',
              title: this.$t('rejectRecommendedIntegration.fail.title'),
              text: this.$t('rejectRecommendedIntegration.fail.text'),
            });
          }
        } catch (error) {
          console.warn('Unable to reject recommended integration', error);
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../../sass/variables/_colors'
  .action-button
    margin-left: auto
    cursor: pointer
    color: #505763
    margin-right: 30px
  .is-hovered
    display: none !important
  .campaign-integration-card-logo
    opacity: 0.5
    min-width: 4.5rem
    min-height: 4.5rem
  .integration-name
    margin-left: 1.25rem
    font-size: 20px
    font-weight: 700
    color: $om-gray-600
    min-width: 13.5rem
  .integration-state-info
    margin: 0 1.25rem
    font-size: 14px
    color: $om-gray-600
  .integration-recommendation
    margin-left: 1.5rem
    margin-right: 3.5rem
</style>
