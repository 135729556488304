<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="tags") {{ $t('tags') }}
        om-tooltip.pl-1
          span {{ $t('integrations.soundest.tooltip.tags') }}
      .col-sm-8
        multiselect#tags(
          v-model="settings.tags"
          :class="['flex-grow-1', 'multiselect-large']"
          :options="tagOptions"
          label="name"
          track-by="value"
          :show-labels="false"
          :closeOnSelect="true"
          @tag="onTag"
          :multiple="true"
          :taggable="true"
          :placeholder="$t('integrations.soundest.tags.placeholder')"
          :tag-placeholder="$t('newCampaignFlow.domain.add')"
        )
          template(slot="noOptions")
            div {{ $t('emptyTags') }}

    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.soundest.fields.sendWelcomeEmail') }}
        om-tooltip.pl-1
          span {{ $t('integrations.soundest.tooltip.sendWelcomeEmail') }}
      .col-sm-8
        toggle-button.mb-0(
          :width="50"
          :height="24"
          v-model="settings.sendWelcomeEmail"
          :sync="true"
        )
  integration-binding(:type="globalIntegration.type" :bindings.sync="bindings")
</template>

<script>
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        settings: {
          tags: [],
          sendWelcomeEmail: false,
        },
      };
    },

    computed: {
      tagOptions() {
        const hasOMTag = this.settings.tags.find((t) => t.value === 'OptiMonk');
        if (hasOMTag) return this.settings.tags;
        const tags = JSON.parse(JSON.stringify(this.settings.tags));
        tags.push({ name: 'OptiMonk', value: 'OptiMonk' });
        return tags;
      },
    },

    mounted() {
      delete this.settings.listId;
      if (this.settings.sourceInputValue) {
        this.onTag(this.settings.sourceInputValue);
      }
      delete this.settings.sourceInputValue;
      this.$emit('loaded');
    },
    methods: {
      onTag(input) {
        const tag = {
          name: input,
          value: input,
        };
        this.settings.tags.push(tag);
      },
    },
  };
</script>
