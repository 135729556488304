<template lang="pug">
div
  .row.align-items-center.mb-2
    .col-sm-4.col-form-label
      label.mb-0 {{ $t('integrations.postscriptLegacy.fields.keyword') }}
    .col-sm-8
      input.form-control(:class="{ 'is-invalid': $v.settings.keyword.$error }" v-model="settings.keyword")

  integration-binding(
    :type="globalIntegration.type"
    :bindings.sync="bindings"
    :fields="fields"
  )

  .alert.alert-danger(v-if="!isValid") {{ $t('integrations.postscriptLegacy.noPhoneBinding') }}
</template>

<script>
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],
    data() {
      return {
        isValid: true,
        settings: {
          keyword: '',
        },
      };
    },

    computed: {
      fields() {
        return [
          {
            name: 'phone',
            id: 'phone',
            required: true,
          },
        ];
      },
    },
    created() {
      this.$emit('loaded');
    },
    methods: {
      customValidation() {
        this.isValid = this.bindings.some((item) => item.type === 'phoneNumber' && item.externalId);

        return this.isValid;
      },
    },

    validations: {
      settings: {
        keyword: {
          required,
        },
      },
    },
  };
</script>
