export default (settings, bindings, brandName) => {
  if (settings.source) {
    bindings.push({
      isFix: true,
      fixValue: settings.sourceText || brandName,
      externalId: settings.source,
    });

    delete settings.source;
    delete settings.sourceText;
  }
};
