<template lang="pug">
.form-group.row.align-items-center
  .col-sm-4.col-form-label
    label.mb-0(for="account") {{ $t('shopifyAccount') }}:
  .col-sm-8
    select#account.form-control(
      :disabled="getActiveShopifyDomains.length <= 1"
      v-model="currentGlobalIntegration.data.shopifyDomain"
    )
      template(v-for="domain in getActiveShopifyDomains")
        option(:value="domain.liveDomain" :selected="getActiveShopifyDomains.length <= 1") {{ domain.liveDomain }}
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: {
      currentGlobalIntegration: {
        type: Object,
      },
    },
    computed: {
      ...mapGetters(['getActiveShopifyDomains']),
    },
  };
</script>
