<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="listName") {{ $t('listName') }}:
      .col-sm-8
        select#listName.form-control(
          :class="{ 'is-invalid': $v.settings.listId.$error }"
          v-model="settings.listId"
        )
          option(:value="null") {{ $t('selectAList') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label.mb-0(for="formName") {{ $t('integrations.salesAutopilot.fields.formId') }}
      .col-sm-8
        select#formName.form-control(
          :class="{ 'is-invalid': $v.settings.formId.$error }"
          :disabled="!lists.length"
          v-model="settings.formId"
        )
          option(:value="null") {{ $t('select') }}
          template(v-for="form in forms")
            option(:value="form.id") {{ form.name }}
  div(v-if="forms.length && settings.formId && fields.length")
    integration-binding(
      :type="globalIntegration.type"
      :bindings.sync="bindings"
      :fields.sync="fields"
    )
</template>

<script>
  import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
  import GET_SALES_AUTOPILOT_FORMS from '@/graphql/GetSalesAutopilotForms.gql';
  import GET_SALES_AUTOPILOT_LIST_FIELDS from '@/graphql/GetSalesAutopilotListFields.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        forms: [],
        fields: [],
        settings: {
          listId: null,
          formId: null,
        },
      };
    },

    validations: {
      settings: {
        listId: {
          required,
        },
        formId: {
          required,
        },
      },
    },

    watch: {
      'settings.listId': async function () {
        this.$apollo
          .query({
            query: GET_SALES_AUTOPILOT_FORMS,
            variables: {
              integrationId: this.globalIntegration._id,
              listId: this.settings.listId,
            },
          })
          .then(({ data: { getSalesAutopilotForms: forms } }) => {
            this.forms = forms;
          });

        this.$apollo
          .query({
            query: GET_SALES_AUTOPILOT_LIST_FIELDS,
            variables: {
              integrationId: this.globalIntegration._id,
              listId: this.settings.listId,
            },
          })
          .then(({ data: { getSalesAutopilotListFields: fields } }) => {
            this.fields = fields;
          });
      },
    },

    created() {
      this.$apollo
        .query({
          query: GET_INTEGRATION_LISTS,
          variables: {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          },
        })
        .then(({ data: { lists } }) => {
          this.lists = lists;
          this.$emit('loaded');
        });
    },
  };
</script>
