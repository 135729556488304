export default (settings, bindings, brandName) => {
  bindings = bindings.map((binding) => {
    if (binding.fieldId === 'email') {
      binding.externalId = 'EMAIL';
    }

    return binding;
  });
  if (settings.firstName && settings.firstName !== '0') {
    const fieldIndex = bindings.findIndex((binding) => {
      return binding.fieldId === 'firstname';
    });
    if (fieldIndex > -1) {
      bindings[fieldIndex].externalId = settings.firstName;
    }

    delete settings.firstName;
  }

  if (settings.lastName && settings.lastName !== '0') {
    const fieldIndex = bindings.findIndex((binding) => {
      return binding.fieldId === 'lastname';
    });
    if (fieldIndex > -1) {
      bindings[fieldIndex].externalId = settings.lastName;
    }

    delete settings.lastName;
  }

  if (settings.hiddenInputName && settings.hiddenInputName !== '0') {
    bindings.push({
      isFix: true,
      fixValue: settings.hiddenInputValue || brandName,
      externalId: settings.hiddenInputName,
    });

    delete settings.hiddenInputName;
    delete settings.hiddenInputValue;
  }
};
