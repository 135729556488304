<template lang="pug">
.brand-analytics-card(@click="$router.push({ name: 'domains', query: { domain: domain.domain } })")
  .d-flex.align-items-center
    domain-integration-status.mr-3(type="analytics" :isIntegrated="isAnalyticsEnabled")
    div(
      v-html="$t(`analyticsSection.statuses.${isAnalyticsEnabled ? 'success' : 'turnedOff'}`, { domain: this.domain.domain })"
    )
</template>

<script>
  import DomainIntegrationStatus from '@/components/DomainIntegrationStatus.vue';
  import domainInfos from '@/mixins/domainInfos';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      DomainIntegrationStatus,
    },
    mixins: [domainInfos],

    props: ['campaign'],

    computed: {
      ...mapGetters(['isSubUser']),
      analyticsType() {
        return this.campaign.analyticsType;
      },
      isAnalyticsEnabled() {
        return this.domain.analytics.enabled;
      },
      getIconByStatus() {
        if (this.isAnalyticsEnabled) {
          return 'far fa-check-circle';
        }

        return 'fas fa-power-off';
      },
    },
  };
</script>

<style lang="sass">
  .brand-analytics-card
    border-radius: 3px
    background-color: rgba(229, 229, 229, 0.32)
    color: #969BA0
    padding: 1rem 1.25rem
    font-size: 0.9375rem
    cursor: pointer
</style>
