export default (settings, bindings, brandName) => {
  if (settings.sourceInputId && settings.sourceFieldValue !== '0') {
    bindings.push({
      isFix: true,
      fixValue: settings.sourceFieldValue || brandName,
      externalId: settings.sourceInputId,
    });

    delete settings.sourceFieldName;
    delete settings.sourceFieldValue;
  }
};
