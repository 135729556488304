<template lang="pug">
multiselect(
  v-model="selectedField"
  label="value"
  track-by="key"
  :options="fields"
  :taggable="true"
  :multiple="false"
  :disabled="isEmailBinding"
  :placeholder="$t('integrationFlow.combinedMultiselect.placeholder')"
  :deselectLabel="$t('integrationFlow.combinedMultiselect.deselect')"
  :selectedLabel="$t('')"
  :select-label="$t('')"
  :tag-placeholder="$t('integrationFlow.combinedMultiselect.tagPlaceholder')"
  :preserveSearch="true"
  :class="{ 'has-error': hasError }"
  @tag="addCustomOption(binding, $event)"
  @input="updateSelectedField(binding, $event)"
)
  span.arrow(slot-scope="{ toggle }" slot="caret" @mousedown.prevent="toggle")
    UilAngleDown(size="1.5em")
</template>

<script>
  import { UilAngleDown } from '@iconscout/vue-unicons';

  export default {
    name: 'CombinedMultiSelect',

    components: {
      UilAngleDown,
    },

    props: {
      binding: {
        type: Object,
        required: true,
      },
      fields: {
        type: Array,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      isEmailBinding: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      selectedField: {
        get() {
          return this.binding.externalId
            ? { key: this.binding.externalId, value: this.binding.externalId || [] }
            : '';
        },
        set(value) {
          const binding = this.binding;
          binding.externalId = value;
          this.$emit('update:binding', binding);
        },
      },
    },

    created() {
      this.checkExternalIdIsInFixFields();
    },

    methods: {
      checkExternalIdIsInFixFields() {
        const isInFixFields = this.fields.some(({ value }) => this.binding.externalId === value);

        if (!isInFixFields && this.binding.externalId) {
          this.addAsField(this.binding.externalId);
        }
      },
      addCustomOption(binding, selectedId) {
        if (!selectedId) return;

        this.addAsField(selectedId);

        this.updateSelectedField(binding, { value: selectedId });
      },

      addAsField(option) {
        const newOption = this.createNewOption(option);
        let fields = this.fields;

        fields.push(newOption);
        fields = fields.sort((field1, field2) => (field1.name > field2.name ? -1 : 1));

        this.$emit('update:fields', fields);
      },

      createNewOption(id) {
        return {
          key: id,
          value: id,
        };
      },

      updateSelectedField(binding, option) {
        if (option?.value) {
          binding.externalId = option.value;
          this.$emit('update:binding', binding);
        }
      },
      // INFO: there were a request for adding tag even on blur after input
      // it can be done with @searchChange and @close event listening, but it is too hacky,
      // so I used preserveSearch option
    },
  };
</script>

<style lang="sass" scoped>
  .integration-new-flow-modal
    .content-step3
      .fields-wrapper
        .has-error
          ::v-deep .multiselect__tags
              border: 1px solid red !important
</style>
